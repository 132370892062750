import React, { useEffect, useState } from "react";
import "./css/OuterPage.css";
import { Link, useLocation } from "react-router-dom";
import { miAPI, URL_USER_LOGOUT } from "../../support/RestAPISpec";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { userSessionLogout } from "../../actions/UserSessionAction";

export const LogoutForm = (props) => {
  // Const message
  const [message, setMessage] = useState("Logging out...");

  // Create the dispatch
  const dispatch = useDispatch();

  // Get the location
  const location = useLocation();

  // On load
  useEffect(() => {
    // Set the message
    let message = "Logged out successfully";

    // If the path contains "nosession", then this is a redirect by
    // system due to invalid session ( when both access_token and refreshtoke is expired)
    if (location.pathname.includes("nosession"))
      message = "Sorry, Please login again !!";

    // Add class on load
    document.body.classList.add("outer-body-bg");

    // Place the API call to the authenticate API
    miAPI
      .post(URL_USER_LOGOUT, {})
      .then((result) => {
        // Dispatch logout
        dispatch(userSessionLogout());

        // Set the message
        setMessage(message);
      })
      .catch((error) => {
        // Log as response
        console.log("Error during logout");

        // Set the message
        setMessage("Logged out failed");
      });

    // cleanup
    return () => {
      document.body.classList.remove("outer-body-bg");
    };
  }, []);

  return (
    <React.Fragment>
      <div id="logreg-forms">
        <div class="header">
          <h1 class="h3 font-weight-normal center-align"> Logged Out </h1>
          <h5 class="font-weight-normal center-align font-weight-normal h5">
            {message}
          </h5>
        </div>
        <form class="form-reset">
          <h6 class="h6 font-weight-normal center-align">
            <Link to="/login">
              Login Again
              <FontAwesomeIcon icon={faAngleRight} />{" "}
            </Link>
          </h6>
        </form>
      </div>
    </React.Fragment>
  );
};

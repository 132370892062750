import React, { useState, useRef } from "react";
import { Card, Row, Col, Button, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as idMasterDataActions from "../../actions/IdeationMasterDataActions";
import {
  miAPI,
  URL_MASTER_ADD_CATEGORY,
  URL_MASTER_ADD_SUBJECT,
} from "../../support/RestAPISpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import { CustomAlert } from "../common/CustomAlert";
import * as masterDataSpec from "../../support/MasterDataSpec";
import SelectSearch from "react-select-search";

export const MasterDataForm = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [entry, setEntry] = useState("");
  const [parentRef, setParentRef] = useState("");

  // Reference to the custom alert
  const alertRef = useRef();

  // Dispatch
  const dispatch = useDispatch();

  // Get the userSession
  const userSession = useSelector((state) => state.userSession);

  // Get the masterData
  const masterData = useSelector((state) => state.masterData);

  const addCategory = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Creating category..."));

    // Call the API
    miAPI
      .post(
        URL_MASTER_ADD_CATEGORY,
        "categoryName=" + entry + "&parentCategoryRef=" + parentRef
      )
      .then((result) => {
        console.log("Result : " + JSON.stringify(result));
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Category saved",
            message: "New category added to the system",
            variant: "success",
          });
          dispatch(
            idMasterDataActions.addMasterCategory(result.data.data, entry,parentRef)
          );
        } else {
          alertRef.current.showAlert({
            heading: "Saving Category",
            message: "Unable to save category : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        console.log("error : " + JSON.stringify(error));
        alertRef.current.showAlert({
          heading: "Saving Category",
          message: "Unable to save category",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  const addSubject = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Creating subject..."));

    // Call the API
    miAPI
      .post(URL_MASTER_ADD_SUBJECT, "subject=" + entry)
      .then((result) => {
        console.log("Result : " + JSON.stringify(result));
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Subject saved",
            message: "New subject added to the system",
            variant: "success",
          });
          dispatch(idMasterDataActions.addMasterSubject(entry));
        } else {
          alertRef.current.showAlert({
            heading: "Saving Subject",
            message: "Unable to save subject : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        console.log("error : " + JSON.stringify(error));
        alertRef.current.showAlert({
          heading: "Saving Subject",
          message: "Unable to save subject",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  const addMasterData = () => {
    if (props.type === "subject") addSubject();
    else addCategory();
  };

  const clearForm = () => {
    alertRef.current.clear();
    alertRef.current.hide();
    setEntry("");
    setParentRef("");
  };

  const closeForm=()=>{
    clearForm();
    setShowForm(false);
  }

  return (
    <>
      <CustomAlert ref={alertRef} />
      {!showForm &&
        (userSession.userType === "ADMIN" ||
          userSession.userType === "SUPER_ADMIN") && (
          <Row>
            <Col md={12}>
              <p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowForm(true);
                  }}
                >
                  Create {props.type}
                </a>
              </p>
            </Col>
          </Row>
        )}
      {showForm && (
        <>
          <Card>
            <Card.Body>
              <Card.Title>Create {props.type}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Define a new {props.type}
              </Card.Subtitle>
              {props.type === "category" && (
                <Row>
                  <Col md={12}>
                    <SelectSearch
                      options={masterDataSpec.getParentCategoryOptions(
                        masterData.categories
                      )}
                      value={parentRef}
                      name="parentCategory"
                      placeholder="Parent Category"
                      search={true}
                      onChange={(e) => setParentRef(e)}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={8}>
                  <FormControl
                    placeholder={"New " + props.type}
                    value={entry}
                    onChange={(e) => setEntry(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <Button
                    variant="success"
                    block
                    onClick={() => addMasterData()}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Button
                    variant="danger"
                    onClick={() => closeForm()}
                    block
                  >
                    Close
                  </Button>
                </Col>
                <Col md={6}>
                  <Button variant="warning" onClick={() => clearForm()} block>
                    Clear
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
        </>
      )}
    </>
  );
};

import React from "react";
import ideaImg from "./img/ideas.png";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Carousel,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./css/IdeationIntro.css";

export const IdeationIntro = (props) => {
  // Define the history
  const history = useHistory();

  return (
    <Container>
      <Row>
        <Col>
          <h4>Create single page content on topics</h4>
          <p>
            A <strong>microideation</strong> is a single page explanation of a
            topic.
          </p>
          <p>
            Everyone wants to learn stuff quick and easy. If you are an expert
            in a field and you can break up a subject into single page topics,
            you can start creating micro ideations.
            <br />
            <br />
          </p>
          <p className="align-center">
            <img src={ideaImg} height={300} />
          </p>
          <p>
            <strong>Design & Share</strong> single page topics under any
            category with ease. Reach out to a large crowd of people who want to
            quick learn stuff.
          </p>
          <p className="align-center">
            <Button
              variant="primary"
              onClick={() => history.push("/portal/ideation-form")}
            >
              Create Ideation
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

import {
  faCalendar,
  faChevronCircleDown,
  faChevronCircleRight,
  faClosedCaptioning,
  faFolderOpen,
  faTimesCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as blActions from "../actions/BlockingLoaderAction";
import { closeIdeationEditRequest } from "../actions/IdeationEditRequestActions";
import {
  IER_STATUS_CLOSED,
  IER_STATUS_OPEN,
} from "../support/IdeationEditRequestSpec";
import {
  miAPI,
  URL_IDEATION_EDIT_REQUEST_AUTHOR_CLOSE_REQUEST,
  URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS,
} from "../support/RestAPISpec";
import { CustomAlert } from "./common/CustomAlert";
export const IdeationeditRequestListItem = (props) => {
  // expanded toggle
  const [expanded, setExpanded] = useState(false);

  // The close comments
  const [closeComments, setCloseComments] = useState("");

  // Create dispatch
  const dispatch = useDispatch();

  // Reference to the history
  const history = useHistory();

  // Reference to the custom alert
  const alertRef = useRef();

  const closeRequest = () => {
    // show the loader
    dispatch(blActions.blLoadingInProgress("Closing edit request"));
    // Create the object to close
    let postData = {
      editRequestId: props.entry.editRequest.id,
      closeComments: closeComments,
    };
    // If page is not specified, then set to 0
    miAPI
      .post(URL_IDEATION_EDIT_REQUEST_AUTHOR_CLOSE_REQUEST, postData)
      .then((result) => {
        if (result.data.status === "success") {
          props.mainAlertRef.current.showAlert({
            heading: "Edit request close",
            message: "Edit request closed with comments",
            variant: "success",
          });
          dispatch(closeIdeationEditRequest(result.data.data));
        } else
          props.mainAlertRef.current.showAlert({
            heading: "Edit request close",
            message:
              "Unable to save the edit request : " + result.data.errorcode,
            variant: "warning",
          });
      })
      .catch((error) => {
        props.mainAlertRef.current.showAlert({
          heading: "Edit request close",
          message: "Error closing ideation edit requests",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={props.entry.editRequest.id}
        onClick={() => setExpanded(!expanded)}
      >
        <Row>
          <Col md={11} sm={10} xs={10}>
            <Row>
              <Col className="d-none d-md-block">
                <p className="mb-0 mt-0">
                  <a
                    href={
                      "/portal/ideation-form/" +
                      props.entry.editRequest.ideationId
                    }
                    target="_blank"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   history.push(
                    //     "/portal/ideation-form/" +
                    //       props.entry.editRequest.ideationId
                    //   );
                    // }}
                  >
                    {props.entry.ideationTopic}
                  </a>
                </p>
                <p className="mb-0 mt-0">
                  <span className="ideation-subject-para">
                    on: <strong>{props.entry.subject}</strong>
                  </span>
                </p>
              </Col>
              <Col md={6} sm={12}>
                <p className="mb-0 mt-0">
                  <FontAwesomeIcon
                    icon={faUser}
                    data-toggle="tooltip"
                    title="Requested user"
                  />
                  &nbsp;
                  {props.entry.editRequest.createdUserName}
                </p>
                <p className="mb-0 mt-0">
                  {props.entry.editRequest.status === IER_STATUS_OPEN ? (
                    <FontAwesomeIcon
                      icon={faFolderOpen}
                      data-toggle="tooltip"
                      title="Opened on"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      data-toggle="tooltip"
                      title="Closed on"
                    />
                  )}
                  &nbsp;
                  <span className="ideation-subject-para">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }).format(
                      new Date(
                        Date.parse(
                          props.entry.editRequest.status === IER_STATUS_OPEN
                            ? props.entry.editRequest.createdDateTime
                            : props.entry.editRequest.closedDateTime
                        )
                      )
                    )}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            md={1}
            sm={2}
            xs={2}
            align="center"
            className="align-self-center"
          >
            {!expanded ? (
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                size="lg"
                data-toggle="tooltip"
                title="Expand"
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronCircleDown}
                size="lg"
                data-toggle="tooltip"
                title="Collapse"
              />
            )}
          </Col>
        </Row>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.entry.editRequest.id}>
        <Container className="mt-3 mb-3">
          <Row>
            <Col md={6}>
              <Form.Label>Reported Edit:</Form.Label>
              <Form.Control
                as="textarea"
                value={props.entry.editRequest.content}
                name="desc"
                rows={5}
                placeholder="A proper description elaborating the series"
                maxLength={500}
                minLength={10}
                readOnly={true}
              />
            </Col>
            <Col md={6}>
              <Form.Label>Closing Comments:</Form.Label>
              <CustomAlert ref={alertRef} />
              <Form.Control
                as="textarea"
                tabIndex="2"
                value={props.entry.editRequest.closeComments}
                name="closeComments"
                rows={3}
                placeholder="Closing comments for the edit"
                maxLength={500}
                minLength={0}
                onChange={(e) => {
                  setCloseComments(e.target.value);
                }}
                readOnly={props.entry.editRequest.status === IER_STATUS_CLOSED}
              />
              {props.entry.editRequest.status !== IER_STATUS_CLOSED ? (
                <Button onClick={() => closeRequest()}>Close Request</Button>
              ) : (
                <span className="ideation-subject-para">
                  Closed on:
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }).format(
                    new Date(Date.parse(props.entry.editRequest.closedDateTime))
                  )}
                </span>
              )}
            </Col>
          </Row>
        </Container>
      </Accordion.Collapse>
    </Card>
  );
};

import * as sdActions from "../actions/ShareDialogActions";

const initialState = {
  show: false,
};

export const ShareDialogReducer = (state = initialState, action) => {
  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case sdActions.SD_SHOW:
      newState.show = true;    
      return newState;

    case sdActions.SD_CLOSE:
      newState.show = initialState.show;
      return newState;

    default:
      return state;
  }
};

import React from "react";
import { Container } from "react-bootstrap";
import "./css/social-contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faGithubSquare
} from "@fortawesome/free-brands-svg-icons";

export const SocialContact = () => {
  return (
    <React.Fragment>
      <Container align="center">
        <div className="social-contact">
          <h5>Reach us on social media !!</h5>
          <span style={{ fontSize: 40 }}>
            <a href="https://twitter.com/microideation" target="blank">
              <FontAwesomeIcon icon={faTwitterSquare} color="#1da1f2" />
            </a>
            &nbsp;
            <a href="https://fb.com/microideation" target="blank">
              <FontAwesomeIcon icon={faFacebookSquare} color="#0980ec" />
            </a>
            &nbsp;
            <a href="https://instagram.com/microideation" target="blank">
              <FontAwesomeIcon icon={faInstagramSquare} color="#cf283e" />
            </a>
            &nbsp;
            <a href="https://github.com/microideation" target="blank">
              <FontAwesomeIcon icon={faGithubSquare} color="black" />
            </a>
          </span>
        </div>
      </Container>
    </React.Fragment>
  );
};

import * as ideatorMetricSpec from "../support/IdeatorMetricSpec";
import * as ideatorMetricActions from "../actions/IdeatorMetricActions";

const initialState = ideatorMetricSpec.StandardIdeatorMetrics();

export const ideatorMetricReducer = (state = initialState, action) => {
  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case ideatorMetricActions.IDEATOR_METRIC_DATA_FETCH:
      if (!newState.metrics) return ideatorMetricSpec.StandardIdeatorMetrics();

      // Check the expiry
      // Create the expiry time object
      var expiryTime = new Date(newState.expiry);

      console.log("Ideator Expiry time is " + expiryTime);

      // If not expired, then return the current state,
      // If expired, return empty list
      if (expiryTime.getTime() > new Date().getTime()) return newState;
      else return ideatorMetricSpec.StandardIdeatorMetrics();

    case ideatorMetricActions.IDEATOR_METRIC_DATA_UPDATE:
      newState.metrics = action.payload;
      newState.fetched = true;
      newState.expiryTime = ideatorMetricSpec.StandardIdeatorMetrics().expiry;
      console.log(
        "on update ideator metric data state : " + JSON.stringify(newState)
      );
      return newState;

    default:
      return state;
  }
};

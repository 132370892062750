export const BL_LOADING_IN_PROGRESS = "BL_LOADING_IN_PROGRESS";
export const BL_LOADING_DONE = "BL_LOADING_DONE";

export const blLoadingInProgress = (loadText) => {
  return {
    type: BL_LOADING_IN_PROGRESS,
    loadText: loadText,
  };
};

export const blLoadingDone = () => {
  return {
    type: BL_LOADING_DONE,
  };
};

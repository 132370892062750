import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  footer {
    padding: 25px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    /* background-color: #222222; */
  }

  footer p {
    font-size: 16px;
    margin: 0;
  }

  footer ul {
    margin-bottom: 0;
  }

  footer ul li a {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  footer ul li a:hover,
  footer ul li a:focus,
  footer ul li a:active,
  footer ul li a.active {
    text-decoration: none;
  }
`;

export const Footer = () => {
  return (
    <Styles>
      <footer>
        <div className="container">
          <p>&copy; Microideation 2021. All Rights Reserved.</p>
          <p>
            Made with &nbsp;
            <FontAwesomeIcon icon={faHeart} size="1x" /> and passion
          </p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                href="https://portal.microideation.com/privacy-policy"
                target="blank"
              >
                Privacy 
              </a> |
            </li>
            <li className="list-inline-item">
              <a
                href="https://portal.microideation.com/terms-and-conditions"
                target="blank"
              >
                Terms
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </Styles>
  );
};

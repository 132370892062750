import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Jumbotron,
  Alert,
} from "react-bootstrap";
import "./css/About.css";
import gstoreImg from "./img/google-play-badge.svg";
import astoreImg from "./img/app-store-badge.svg";
import catPrefImg from "./img/category-preferences.jpg";
import idViewImg from "./img/ideation-view.jpg";
import idListing from "./img/ideation-listing.jpg";
import idEditorImg from "./img/ideation-editor.png";
import idPreviewImg from "./img/ideation-preview.png";
import idMetricsImg from "./img/ideator-metrics.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faBullseye,
  faUserFriends,
  faChalkboardTeacher,
  faUser,
  faSearch,
  faUserEdit,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";

export const About = (props) => {
  const [showDlg, setShowDlg] = useState(true);
  const history = useHistory();
  return (
    <React.Fragment>
      <Container className="mi-details-container">
        <Row>
          <Col>
            <h4 align="center" style={{ marginTop: "0px" }}>
              About Microideation
            </h4>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <i>
                In this information age, we have numerous options to find the
                information we need about pretty much anything. But learning
                something new is still a process where you need to find the
                dedicated time and spend hours understanding the concepts.
              </i>
            </p>
            <Alert variant="info">
              <h4 align="center" style={{ marginTop: "0px" }}>
                <strong>
                  <i>
                    Microideation creates an anywhere-anytime discovery and
                    learning option of preferred topics that are explained in
                    500 characters or less.
                  </i>
                </strong>
              </h4>
            </Alert>
            <p>
              You can discover brand new expert-crafted topics on each swipe.
              This allows for the continuous discovery of relevant topics
              irrespective of whether you are on a metro train heading home or
              while having a peaceful cup of morning coffee. If you found your
              favorite subject/topic and want to see more, just freeze the
              ideation flow and follow them continuously.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 align="center" style={{ marginTop: "20px" }} id="about-what-is">
              What is... ?
            </h4>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faUserFriends} color="orange" /> &nbsp;
                  Microideation
                </Card.Title>
                <i>
                  Microideation is a platform for the subject matter experts to
                  craft rich content ideations that can be discovered by the
                  inquisitive minds based on their preferences.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faLightbulb} color="orange" /> &nbsp;
                  Ideation
                </Card.Title>
                <i>
                  A microideation (or simply ideation ) is a concrete
                  explanation of a topic within 500 characters created by
                  subject-matter-experts on the field using rich formatting and
                  images.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faUser} color="orange" /> &nbsp;Ideator
                </Card.Title>
                <i>
                  An ideator is a subject-matter expert who can explain a large
                  subject in simple topics of 500 or less characters. The
                  ideator crafts ideations using rich content, images and tags.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 align="center" style={{ marginTop: "40px" }}>
              <FontAwesomeIcon icon={faTasks} color="#df4b3b" />
              &nbsp;Features
            </h4>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Card className="feature_section">
              <Card.Body>
                <Card.Title>Learning Platorm</Card.Title>
                <i>
                  Provides a platform for subject matter experts to create quick
                  learning content and users to learn topics with easy
                  understanding.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="feature_section">
              <Card.Body>
                <Card.Title>Quick &amp; Concise</Card.Title>
                <i>
                  Topics are explained in less than 500 characters using rich
                  text and images and is crafted by subject-matter experts in
                  the respective fields.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="feature_section">
              <Card.Body>
                <Card.Title>Engaging content</Card.Title>
                <i>
                  Discover new content every time you swipe up in an ideation
                  flow. Ideations are presented in the order of your interest
                  that the system learns over time.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="feature_section">
              <Card.Body>
                <Card.Title>Follow interest</Card.Title>
                <i>
                  Freeze and follow a specific subject or topic to see ideations
                  under them. Users can deep dive into interested topics or skip
                  to next ideation.
                </i>
                <br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 align="center" style={{ marginTop: "40px" }}>
              <FontAwesomeIcon icon={faSearch} color="#df4b3b" />
              &nbsp; Discover ideations
            </h4>
            <hr />
            <p>
              Users can discover the ideations based on their preferred
              categories and the ideations are presented to them in a non-linear
              order. User can freeze a topic / subject and follow the respective
              tag to see related content.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>Choose Preferences</Card.Title>
                <i>
                  User can choose their preferred categories and the ideations
                  are displayed based on popularity or by the latest entries.
                </i>
                <br />
                <br />
                <img src={catPrefImg} width="300" />
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>View Ideation</Card.Title>
                <i>
                  The ideation view displays the ideation with the same rich
                  content and can be favorited. User can swipe to view next
                  recommended ideation.
                </i>
                <br />
                <br />
                <img src={idViewImg} width="300" />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>Explore Ideations</Card.Title>
                <i>
                  Users can also choose to explore the recommended ideations or
                  search them based on topic / subject. History of ideation is
                  also available.
                </i>
                <br />
                <br />
                <img src={idListing} width="300" />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="success">
              <Alert.Heading>
                Are you looking for quick and concise content on topics you want
                to learn?
                <hr />{" "}
              </Alert.Heading>
              <p>
                Microideation provides access to quick and concise ideations
                crafted by subject matter experts. Topics are explained in less
                than 500 characters using rich text and images. You can choose
                your preferred categories and learn anytime, anywhere.
              </p>
              <p>
                <a
                  className="badge-link"
                  href="https://play.google.com/store/apps/details?id=com.microideation.app"
                  target="_blank"
                >
                  <img src={gstoreImg} width="240" alt="" />
                </a>
                &nbsp;&nbsp;
                <a
                  className="badge-link"
                  href="https://apps.apple.com/us/app/microideation/id1582752866"
                  target="_blank"
                >
                  <img src={astoreImg} width="240" alt="" />
                </a>
              </p>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 align="center" style={{ marginTop: "40px" }}>
              <FontAwesomeIcon icon={faUserEdit} color="#df4b3b" />
              &nbsp;Create and share quality,concise content
            </h4>
            <hr />
            <p>
              An ideator can design an ideation on a particular topic using rich
              content. Ideations are displayed to interested users based on
              their chosen preferences and popularity. The ideator will be
              ranked based on their popularity and acceptance.
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>Craft ideation</Card.Title>
                <i>
                  Create an ideation using rich formatting options and use of
                  images. The ideation can be tagged under relvant categories
                  and subject.
                </i>
                <br />
                <br />
                <img src={idEditorImg} width="300" />
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>Preview ideation</Card.Title>
                <i>
                  Realtime preview of the ideation during the editing time
                  provides the look and feel of how the ideation will be
                  displayed to the user.
                </i>
                <br />
                <br />
                <img src={idPreviewImg} width="300" />
                <br />
                <br />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} align="center">
            <Card>
              <Card.Body>
                <Card.Title>Ideator Recognition</Card.Title>
                <i>
                  Public profile for the ideator that is visible to users.
                  Displays information on the post, views and favorites
                  received.
                </i>
                <br />
                <br />
                <img src={idMetricsImg} width="260" />
                <br />
                <br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert
              variant="primary"
              show={showDlg}
              onClose={() => setShowDlg(!showDlg)}
              dismissible
            >
              <Alert.Heading>
                Are you a subject matter expert?
                <hr />{" "}
              </Alert.Heading>
              <p>
                If you are an expert in a field and can explain topics in easy
                and concise content,{" "}
                <a href="#" onClick={() => history.push("register")}>
                  register now.
                </a>{" "}
                Start creating ideations and build reputation among the users
                trying to learn topics.
              </p>
              <p align="right">
                <Button variant="danger" onClick={() => setShowDlg(false)}>
                  Close
                </Button>
                &nbsp;
                <Button
                  onClick={() => history.push("register")}
                  variant="success"
                >
                  Register Now
                </Button>
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export const IDEATION_NEW = "IDEATION_NEW";
export const IDEATION_PUBLISHED = "IDEATION_PUBLISHED";
export const IDEATION_SUSPENDED = "IDEATION_SUSPENDED";
export const IDEATION_EDIT = "IDEATION_EDIT";
export const IDEATION_SAVED = "IDEATION_SAVED";
export const IDEATION_OWN_LIST_FETCHED = "IDEATION_OWN_LIST_FETCHED";
export const IDEATION_OWN_SEARCH_TRIGGERED = "IDEATION_OWN_SEARCH_TRIGGERED";
export const IDEATION_UPDATE_UNSAVED = "IDEATION_UPDATE_UNSAVED";

// Ideation object
export const Ideation = () => {
  return {
    id: null,
    tags: "",
    categoryRef: "",
    subject: "",
    topic: "",
    content: "",
    author: "",
    status: "NEW",
    publishAt: "",
    createdAt: "",
  };
};

import { userSessionReducer } from "./UserSessionReducer";
import { ideationReducer } from "./IdeationReducer";
import { blockingLoaderReducer } from "./BlockingLoaderReducer";
import { ideationMasterDataReducer } from "./IdeationMasterDataReducer";
import { ideatorMetricReducer } from "./IdeatorMetricReducer";
import { combineReducers } from "redux";
import * as userSessionSpec from "../support/UserSessionSpec";
import storage from "redux-persist/lib/storage";
import { ideatorProfileReducer } from "./IdeatorProfileReducer";
import { ShareDialogReducer } from "./ShareDialogReducer";
import { ideationSeriesReducer } from "./IdeationSeriesReducer";
import { UnsavedReducer } from "./UnsavedReducer";
import { ideationEditRequestReducer } from "./IdeationEditRequestReducer";

// Create the appReducer by combining all the reducers
export const appReducer = combineReducers({
  ideation: ideationReducer,
  userSession: userSessionReducer,
  blockingLoader: blockingLoaderReducer,
  masterData: ideationMasterDataReducer,
  ideatorMetrics: ideatorMetricReducer,
  ideatorProfile: ideatorProfileReducer,
  shareDialog: ShareDialogReducer,
  ideationSeries: ideationSeriesReducer,
  unsaved: UnsavedReducer,
  ideationEditRequests: ideationEditRequestReducer
});

// Define the rootReducer which returns appReducer after checking
// state to see if it is USER_SESSION_LOGOUT
// Reference : https://stackoverflow.com/a/35641992/812853
export const rootReducer = (state, action) => {
  // Check if the action is logout, session expired
  if (
    action.type === userSessionSpec.USER_SESSION_LOGOUT ||
    action.type === userSessionSpec.USER_SESSION_EXPIRED
  ) {
    // Remove the item from storage
    storage.removeItem("persist:root");

    // Set the status to empty object
    state = {};
  }

  // Return the reducer
  return appReducer(state, action);
};

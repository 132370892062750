import * as ideationSpec from "../support/IdeationSpec";
import * as unsavedSpec from "../support/UnsavedSpec";

const initialState = {
  ideations: {},
};

const isExistListItem = (list, key) => {
  return list[key] && list[key] !== undefined;
};

const markAsSavedInList = (list, obj) => {
  // if the state.unsaved is undefined, then set empty array
  if (!list) {
    return;
  }

  // If the unsavedId is false, then return
  if (!obj || !obj.unsavedId) return;

  //   const idxIndex = list.findIndex((item) => item.unsavedId === obj.unsavedId);

  //If the index is greater than 0, remove the item from list
  // using splice
  if (isExistListItem(list, obj.unsavedId)) {
    list[obj.unsavedId] = { ...obj, unSavedStatus: "saved" };
  }
};

const discardUnsavedItem = (list, unsavedId) => {
  console.log("deleting : " + unsavedId);
  // if the state.unsaved is undefined, then set empty array
  if (!list) {
    return;
  }

  // If the unsavedId is false, then return
  if (!unsavedId) return;

  // Delete the entry from the list
  delete list[unsavedId];
};

const updateUnsavedItem = (list, obj) => {
  list[obj.unsavedId] = { ...obj, unsavedTimestamp: new Date() };
};

const updateUnsavedList = (list, obj, type) => {
  // if the state.unsaved is undefined, then set empty array
  if (!list) {
    return;
  }

  // If the unsavedId is false, then return
  if (!obj || !obj.unsavedId) return;

  //If the index is greater than 0, then update the list
  if (isExistListItem(list, obj.unsavedId)) {
    // Check if we need to update the entry based on the unSavedStatus
    if (isValidToUpdateUnsaved(list[obj.unsavedId], obj, type)) {
      // Update the item if its
      updateUnsavedItem(list, obj);
    }
  } else {
    // Update
    updateUnsavedItem(list, obj);
  }
};

const isValidToUpdateUnsaved = (item, newItem, type) => {
  // Check if the current item has saved status
  if (item.unSavedStatus && item.unSavedStatus === "saved") {
    // Check if the entry is matching
    var itemClone = Object.assign({}, item);
    var newItemClone = Object.assign({}, newItem);

    delete itemClone.unSavedStatus;
    delete itemClone.unsavedTimestamp;
    delete newItemClone.unSavedStatus;
    delete newItemClone.unsavedTimestamp;

    // Check if the incoming and existing are matching for JSON
    // If they are different, we need to update the entry
    if (type === unsavedSpec.UNSAVED_TYPE_IDEATION) {
      if (
        JSON.stringify(getIdeationObjWithRequiredFields(itemClone)) !==
        JSON.stringify(getIdeationObjWithRequiredFields(newItemClone))
      ) {
        return true;
      }
    }
    // Return false otherwise
    return false;
  }

  // Return true
  return true;
};

const getIdeationObjWithRequiredFields = (sourceObj) => {
  return {
    id: sourceObj.id,
    categoryRef: sourceObj.categoryRef,
    subject: sourceObj.subject,
    topic: sourceObj.topic,
    content: sourceObj.content,
    slug: sourceObj.slug,
    unsavedId: sourceObj.unsavedId,
    unSavedStatus: sourceObj.unSavedStatus,
  };
};

const isIdeationFieldsEmpty = (obj) => {
  if (
    obj.categoryRef === "" &&
    obj.subject === "" &&
    obj.topic === "" &&
    obj.content === ""
  )
    return true;

  return false;
};

export const UnsavedReducer = (state = initialState, action) => {
  // IMPORTANT NOTE: It is required to create a new instance of the
  // state every time from current state and update the fields to
  // have redux-persist work properly and update the fields correct
  // This is mandatory in the case of multikey state ( like ideation )
  // as the object may remain same if we are ony updating 1 field

  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case ideationSpec.IDEATION_SAVED:
      // Mark the object as saved
      markAsSavedInList(newState.ideations, action.payload);

      // Return the new state
      return newState;

    case unsavedSpec.ACTION_DISCARD_UNSAVED_ITEM:
      // check the type and call the discart
      if (action.payload.listType === unsavedSpec.UNSAVED_TYPE_IDEATION) {
        discardUnsavedItem(newState.ideations, action.payload.unsavedId);
      }

      // Return the newState
      return newState;

    case ideationSpec.IDEATION_EDIT:
      // Push the ideation to the unsavedList
      // Check if the unsavedId is present and is unSaved
      if (
        action.payload.unsavedId &&
        action.payload.unSavedStatus !== "saved"
      ) {
        updateUnsavedList(
          newState.ideations,
          {
            ...action.payload,
            unsavedId: action.payload.id,
            unSavedStatus: "",
          },
          unsavedSpec.UNSAVED_TYPE_IDEATION
        );
      } else {
        updateUnsavedList(
          newState.ideations,
          {
            ...action.payload,
            unsavedId: action.payload.id,
            unSavedStatus: "saved",
          },
          unsavedSpec.UNSAVED_TYPE_IDEATION
        );
      }

      // Return the new state
      return newState;
    case ideationSpec.IDEATION_UPDATE_UNSAVED:
      // If the ideation required fields are empty, then we don't keep
      // it as unsaved ( case of new without any content)
      if (isIdeationFieldsEmpty(action.payload)) return newState;

      // Push the ideation to the unsavedList
      updateUnsavedList(
        newState.ideations,
        action.payload,
        unsavedSpec.UNSAVED_TYPE_IDEATION
      );

      // Return the new state
      return newState;
    default:
      return state;
  }
};

export const IDP_ACTION_REFRESH_PUBLISHED_IDEATIONS="IDP_REFRESH_PUBLISHED_IDEATIONS";
export const IDP_ACTION_ADD_PUBLISHED_IDEATION="IDP_ADD_PUBLISHED_IDEATION";
export const IDP_ACTION_REMOVE_SUSPENDED_IDEATION="IDP_REMOVE_SUSPENDED_IDEATION";

export const refreshPublishedIdeations=(ideations) =>{
    return {
        type: IDP_ACTION_REFRESH_PUBLISHED_IDEATIONS,
        payload: ideations
    }
}


export const addPublishedIdeation=(ideation) =>{
    return {
        type: IDP_ACTION_ADD_PUBLISHED_IDEATION,
        payload: ideation
    }
}


export const removeSuspendedIdeation=(ideation) =>{
    return {
        type: IDP_ACTION_REMOVE_SUSPENDED_IDEATION,
        payload: ideation
    }
}
// Middleware to set or remove the token from the sessionStorage
// based on the action type
export const actionLoggingMiddleware = ({ getState }) => {
  return (next) => (action) => {
    // Log the action
    console.log("LOGGED ACTION : " + action.type);

    // Call the next middleware in the chain and get return
    const returnValue = next(action);

    // // Return the value ( required )
    return returnValue;
  };
};

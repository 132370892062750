import React from "react";
import { faLightbulb, faSearch } from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./css/external-common-section.css";

export const ExternalCommonSection = () => {
  return (
    <Container className="content-container" id="external-comm-section">
      <Row className="content-section-border">
        <div className="content-section">
          <Col xs="2" md="2" lg="2" className="content-image">
            <FontAwesomeIcon icon={faLightbulb} size="2x" color="orange" />
          </Col>
          <Col xs="10" md="10" lg="10" className="content-text">
            Create and share <a href="../about#about-what-is">ideations</a> on topics of your expertise and get recognized.
          </Col>
        </div>
      </Row>
      <Row>
        <div className="content-section">
          <Col xs="2" md="2" lg="2" className="content-image">
            <FontAwesomeIcon icon={faSearch} size="2x" color="green" />
          </Col>
          <Col xs="10" md="10" lg="10" className="content-text">
            Discover ideas and topics explained in a single screen.
          </Col>
        </div>
      </Row>
    </Container>
  );
};

import React from "react";
import { Badge } from "react-bootstrap";
import * as masterSpec from "../../support/MasterDataSpec";
import "../portal/css/ideation-series-styles.css";

export const IdeationSeriesDraggableItem = (props) => {
  return (
    <div
      className="ideation-series-ideation-item"
      // key={item.id.toString()}
      ref={props.drgProvided.innerRef}
      {...props.drgProvided.draggableProps}
      {...props.drgProvided.dragHandleProps}
    >
      <div className="ideation-series-ideation-info-section">            
        <h5 data-toggle="tooltip" title={props.item.topic}><b>{props.index+1}. </b>{props.item.topic}</h5>
      </div>
      <div className="ideation-series-ideation-info-section">
        on: <i>{props.item.subject}</i>
        <Badge
          style={{ float: "right" }}
          pill
          variant="warning"
          data-toggle="tooltip"
          title={masterSpec.getCategoryForRef(
            props.masterCategories,
            props.item.categoryRef
          )}
        >
          {masterSpec.getCategoryForRef(
            props.masterCategories,
            props.item.categoryRef
          )}
        </Badge>
      </div>
    </div>
  );
};

import React, { useState, useRef, useEffect } from "react";
import "./css/OwnIdeationList.css";
import { miAPI, URL_IDEATION_LIST_OWN } from "../../support/RestAPISpec";
import { CustomAlert } from "../common/CustomAlert";
import {
  Container,
  Row,
  Col,
  Button,
  FormControl,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  faThumbsUp,
  faSync,
  faBullseye,
  faHeart,
  faShareAlt,
  faTimes,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  ideationOwnListFetched,
  ideationOwnSearchTriggered,
} from "../../actions/IdeationAction";
import { useSelector, useDispatch } from "react-redux";
import { IdeationIntro } from "./IdeationIntro";
import * as blActions from "../../actions/BlockingLoaderAction";
import { ShareDialog } from "../common/ShareDialog";
import * as sdActions from "../../actions/ShareDialogActions";
import { ButtonGroup } from "react-bootstrap";
import { SplitButton } from "react-bootstrap";
import { ButtonToolbar } from "react-bootstrap";
import { UnsavedList } from "../UnsavedList";
import * as unsavedSpec from "../../support/UnsavedSpec";

export const OwnIdeationListForm = (props) => {
  // Constants
  const [endOfList, setEndOfList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [shareIdeation, setShareIdeation] = useState({});

  // Reference to the custom alert
  const alertRef = useRef();

  // Reference to the history
  const history = useHistory();

  // Get the ideationList
  const ideationList = useSelector((state) => state.ideation.list);

  // Get the page
  const currPage = useSelector((state) => state.ideation.currPage);

  // Get the filter
  const filter = useSelector((state) => state.ideation.filter);

  // Get the query
  const query = useSelector((state) => state.ideation.query);

  // Get the unsaved
  const unsaved = useSelector((state) => state.unsaved);

  // Create dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    // Set the search query as the query from the state if its not default
    if (filter != "all") setSearchQuery(query);
  }, []);

  // Trigger search on filter change
  useEffect(() => {
    fetchOwnIdeations();
  }, [filter]);

  const getUnsavedList = () => {
    return unsavedSpec.getUnsavedEntries(unsaved.ideations).map((item) => ({
      heading: item.topic,
      subHeading: item.subject,
      unsavedId: item.unsavedId,
      unsavedTimestamp: item.unsavedTimestamp,
    }));
  };

  // Method to reset the search
  const resetSearch = () => {
    dispatch(ideationOwnSearchTriggered("all", "all"));
    setSearchQuery("");
  };

  // Method to trigger the search
  const triggerSearch = (filter) => {
    // // // Send the search event
    dispatch(ideationOwnSearchTriggered(filter, searchQuery));
  };

  // Method to parse the metric value
  const getMetricValue = (ideation, type) => {
    let value = 0;

    if (type === "VIEW") value = ideation.views;
    else if (type === "FAVORITES") value = ideation.favorites;

    // Check if ideation and metrics are existing, else return 0
    if (!value || value === null) return 0;

    // Return value
    return value;
  };

  // Read the own ideations for next page
  const fetchOwnIdeations = () => {
    // show the loader
    dispatch(blActions.blLoadingInProgress("Loading ideations"));

    // If page is not specified, then set to 0
    miAPI
      .get(
        URL_IDEATION_LIST_OWN +
          "/" +
          filter +
          "/" +
          query +
          "?page=" +
          currPage +
          "&size=10&sort=createdDate,desc"
      )
      .then((result) => {
        // Check the status
        if (result.data.status === "success") {
          // if the page + 1 is matching total pages, then set end of list of true
          if (parseInt(result.data.pagenumber) + 1 >= result.data.totalpages) {
            setEndOfList(true);
          } else {
            setEndOfList(false);
          }
          dispatch(ideationOwnListFetched(result.data.data, currPage + 1));
        } else {
          alertRef.current.showAlert({
            heading: "Ideation Fetch",
            message: "Error retrieving ideations : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Ideation Fetch",
          message: "Error retrieving ideations",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <div className="section_container content_section">
      <ShareDialog item={shareIdeation} type={sdActions.SD_TYPE_IDEATION} />
      <div className="content_header">
        <Row>
          <Col md={12}>
            <div className="content_header_heading">
              <h5>My Ideations</h5>
            </div>
            <div className="content_header_controls">
              <Row>
                <Col md={4}>
                  <Button
                    variant="success"
                    onClick={() => history.push("/portal/ideation-form")}
                    block
                  >
                    Create New
                  </Button>
                </Col>
                <Col md={{ span: 8 }}>
                  <InputGroup className="">
                    <InputGroup.Prepend>
                      <Button onClick={() => resetSearch()} variant="primary">
                        {searchQuery !== "" ? (
                          <FontAwesomeIcon icon={faTimes} />
                        ) : (
                          <FontAwesomeIcon icon={faSearch} />
                        )}
                      </Button>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="Search text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(event) =>
                        event.key === "Enter" ? triggerSearch("topic") : null
                      }
                    />
                    <InputGroup.Append>
                      {/* <Button onClick={() => triggerSearch()} variant="primary">
                        Search
                      </Button> */}
                      <Button
                        variant="primary"
                        onClick={() => triggerSearch("topic")}
                      >
                        Topic
                      </Button>{" "}
                      <Button
                        variant="primary"
                        onClick={() => triggerSearch("subject")}
                      >
                        Subject
                      </Button>{" "}
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content_section_form">
        <Container>
          <CustomAlert ref={alertRef} />
        </Container>
        <Row>
          <Col>
            <Container>
              <UnsavedList
                unsavedList={getUnsavedList()}
                editBaseUrl="/portal/ideation-form/unsaved"
                type={unsavedSpec.UNSAVED_TYPE_IDEATION}
                title="Unsaved ideations"
              />
            </Container>
            <Container>
              {ideationList.length === 0 && (
                <Row>
                  <Col align="center" md={{ span: 6, offset: 3 }}>
                    <h3>No ideations found!!</h3>
                    <br />
                    <br />
                    <IdeationIntro />
                  </Col>
                </Row>
              )}
              {ideationList.map((ideation) => (
                <Row
                  className="ideation-item align-items-center"
                  key={ideation.id}
                >
                  <Col md={3}>
                    <p>
                      {ideation.topic}
                      <br />
                      <span className="ideation-subject-para">
                        on: <strong>{ideation.subject}</strong>
                      </span>
                    </p>
                  </Col>
                  <Col md={1}>
                    {ideation.status === "PUBLISHED" && (
                      <FontAwesomeIcon
                        data-toggle="tooltip"
                        title="Share Ideation"
                        className="ideation_list_form_icon"
                        icon={faShareAlt}
                        size="2x"
                        onClick={() => {
                          setShareIdeation(ideation);
                          dispatch(sdActions.showShareDialog());
                        }}
                      />
                    )}
                  </Col>
                  <Col md={3}>
                    <span className={ideation.status.toLowerCase()}>
                      {ideation.status}
                      <br />
                      <span className="ideation-subject-para">
                        {ideation.status !== "PUBLISHED" &&
                          ideation.createdDate && (
                            <p>
                              Created : &nbsp;
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }).format(new Date(ideation.createdDate))}
                            </p>
                          )}
                        {ideation.status === "PUBLISHED" && ideation.publishAt && (
                          <p>
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                            }).format(new Date(Date.parse(ideation.publishAt)))}
                          </p>
                        )}
                      </span>
                    </span>
                  </Col>
                  <Col md={1}>
                    <p>
                      <FontAwesomeIcon icon={faBullseye} />
                      &nbsp;
                      {getMetricValue(ideation, "VIEW")}
                    </p>
                  </Col>
                  <Col md={1}>
                    <p>
                      <FontAwesomeIcon icon={faThumbsUp} />
                      &nbsp;
                      {getMetricValue(ideation, "UPVOTE")}
                    </p>
                  </Col>
                  <Col md={1}>
                    <p>
                      <FontAwesomeIcon icon={faHeart} />
                      &nbsp;
                      {getMetricValue(ideation, "FAVORITES")}
                    </p>
                  </Col>

                  <Col md={2}>
                    <Button
                      block
                      size="sm"
                      onClick={() =>
                        history.push("/portal/ideation-form/" + ideation.id)
                      }
                    >
                      Edit / View
                    </Button>
                  </Col>
                </Row>
              ))}
            </Container>
            <Container>
              <Row>
                <Col align="center">
                  {!endOfList && (
                    <a href="#" onClick={() => fetchOwnIdeations()}>
                      <p>Load More</p>
                    </a>
                  )}
                  {endOfList && ideationList.length > 0 && (
                    <p>No more ideations !!</p>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const IDEATION_MASTER_DATA_FETCH = "IDEATION_MASTER_DATA_FETCH";
export const IDEATION_MASTER_DATA_UPDATE = "IDEATION_MASTER_DATA_UPDATE";
export const IDEATION_MASTER_ADD_CATEGORY = "IDEATION_MASTER_ADD_CATEGORY";
export const IDEATION_MASTER_ADD_SUBJECT = "IDEATION_MASTER_ADD_SUBJECT";

export const ideationMasterDataFetch = () => {
  return {
    type: IDEATION_MASTER_DATA_FETCH,
    payload: {},
  };
};

export const ideationMasterDataUpdate = (masterData) => {
  return {
    type: IDEATION_MASTER_DATA_UPDATE,
    payload: masterData,
  };
};

export const addMasterCategory = (catId, catName,parentCategory) => {
  return {
    type: IDEATION_MASTER_ADD_CATEGORY,
    payload: {
      catId: catId,
      catName: catName,
      parentCategory: parentCategory
    },
  };
};

export const addMasterSubject = (subject) => {
  return {
    type: IDEATION_MASTER_ADD_SUBJECT,
    payload: {
      subject: subject,
    },
  };
};

import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import "./css/blocking-loader.css";
import loaderGif from "./img/loader.gif";

export const BlockingLoader = (props) => {
  // Get the loading data
  const loaderState = useSelector((state) => state.blockingLoader);

  return (
    <Fragment>
      {loaderState.loading && (
        <div className="loader-container">
          <div className="loader">
            <img src={loaderGif} />
            <h4>{loaderState.loadText}</h4>
          </div>
        </div>
      )}
    </Fragment>
  );
};

import React, { createRef, useEffect, useState } from "react";
import "./css/OuterPage.css";
import { Link, useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import {
  miAPI,
  URL_RESET_PASSWORD,
  URL_VRF_ITEM_STATUS,
} from "../../support/RestAPISpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import { useDispatch } from "react-redux";
import { CustomAlert } from "../common/CustomAlert";
import { Container } from "react-bootstrap";
import ideaImg from "./img/ideas.png";
import { ExternalCommonSection } from "./ExternalCommonSection";

export const ResetPasswordProcessForm = (props) => {
  // Define the identifier
  const [resetRequest, setResetRequest] = useState({});
  const [resetProcessed, setResetProcessed] = useState(false);
  const [linkValid, setLinkValid] = useState(false);

  // Create the dispatch
  const dispatch = useDispatch();

  // Get the id from the route params
  const { id } = useParams();

  // Get the reference to the history
  const history = useHistory();

  // The alert ref
  const alertRef = React.useRef(null);

  // Check the id
  useEffect(() => {
    // Check if the id is present
    if (!id) {
      alertRef.current.showAlert({
        heading: "Password Reset",
        message: "No verification code passed.",
        variant: "warning",
      });
      setLinkValid(false);
    } else {
      getVrfItemStatus();
    }
  }, [id]);

  // Disable the loading if already in progress
  useEffect(() => {
    dispatch(blActions.blLoadingDone());
  }, []);

  // Handle the change
  const handleChange = (event) => {
    setResetRequest({
      ...resetRequest,
      [event.target.name]: event.target.value,
    });
  };

  // Check the status
  const getVrfItemStatus = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Checking link..."));

    miAPI
      .get(URL_VRF_ITEM_STATUS + "/" + id)
      .then((result) => {
        // Check the status
        if (
          result.data.status !== "success" ||
          result.data.data !== "CREATED"
        ) {
          alertRef.current.showAlert({
            heading: "Password Reset",
            message: "Verification code is not valid.",
            variant: "warning",
          });
          setLinkValid(false);
        } else {
          setLinkValid(true);
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Password Reset",
          message: "Unable to validate link. Please try again later.",
          variant: "warning",
        });
        setLinkValid(false);
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Initiate the password call
  const processPasswordReset = (event) => {
    // Prevent the default action
    event.preventDefault();

    // Clear the alertRef
    alertRef.current.clear();

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Resetting password..."));

    // Set the id as the verification code
    resetRequest.vrfCode = id;

    // Make the API call
    miAPI
      .post(URL_RESET_PASSWORD, resetRequest)
      .then((result) => {
        if (result.data.status === "success") {
          alertRef.current.showAlert({
            heading: "Reset",
            message: "Link sent succesfully!",
            variant: "success",
          });
          setResetProcessed(true);
        } else {
          alertRef.current.showAlert({
            heading: "Reset",
            message: result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Reset",
          message: "Unable to initate password reset",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <React.Fragment>
      <div id="logreg-forms">
        {!resetProcessed && (
          <React.Fragment>
            <div className="header">
              <h3 className="h3 font-weight-normal center-align">
                <strong>Reset Password</strong>
              </h3>
            </div>
            <form
              className="form-reset"
              onSubmit={(e) => processPasswordReset(e)}
            >
              <CustomAlert ref={alertRef} />
              <h6 className="font-weight-normal font-weight-normal h6">
                Provide details...
              </h6>
              <input
                disabled={!linkValid}
                type="password"
                name="password"
                className="form-control dissolved-textfield-top"
                placeholder="Password"
                required
                autoFocus
                onChange={(e) => handleChange(e)}
              />
              <input
                disabled={!linkValid}
                type="password"
                name="rePassword"
                className="form-control dissolved-textfield-bottom"
                placeholder="Re-password"
                required
                autoFocus
                onChange={(e) => handleChange(e)}
              />
              <button
                disabled={!linkValid}
                className="btn btn-primary btn-block"
                type="submit"
              >
                Update Password
              </button>
            </form>
          </React.Fragment>
        )}

        {resetProcessed && (
          <Container align="center">
            <br />
            <h4>Password updated!!</h4>
            <p>
              Login and start ideating!!.
              <br />
              <br />
              <img src={ideaImg} height={200} />
            </p>
            <ExternalCommonSection />
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={(e) => history.push("/login")}
            >
              <FontAwesomeIcon icon={faSignInAlt} /> Sign In{" "}
            </button>
            <br />
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

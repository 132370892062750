import * as blActions from "../actions/BlockingLoaderAction";

const initialState = {
  loading: false,
  loadText: "Please wait..",
};

export const blockingLoaderReducer = (state = initialState, action) => {
  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case blActions.BL_LOADING_IN_PROGRESS:
      newState.loading = true;
      if (action.loadText) newState.loadText = action.loadText;
      return newState;

    case blActions.BL_LOADING_DONE:
      newState.loading = initialState.loading;
      newState.loadText = initialState.loadText;
      return newState;

    default:
      return state;
  }
};

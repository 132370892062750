import axios from 'axios';

// Define the constants
export const BASE_URL                = "https://portal.microideation.com/gateway/"; 
// export const BASE_URL                = "http://develop.microideation.local:8080/gateway/"; 

export const URL_USER_AUTHENTICATE   = "api/web/authenticate";
export const URL_USER_REFRESH_TOKEN  = "api/web/refresh_token";
export const URL_USER_LOGOUT         = "api/web/logout";
export const URL_REGISTER_USER       = "auth/public/user/app/register";
export const URL_PASSWORD_RESET_INIT = "auth/public/user/app/password-reset/initiate";
export const URL_RESEND_ACTIVATE_LINK= "auth/public/user/app/account/activate/resend/link";
export const URL_RESET_PASSWORD      = "auth/public/user/app/password-reset/reset";
export const URL_ACCOUNT_ACTIVATE    = "auth/public/user/app/account/activate";
export const URL_VRF_ITEM_STATUS     = "auth/public/verification-item";

export const URL_IDEATION_PUBLISH    = "ideation/ideation/publish";
export const URL_IDEATION_SUSPEND    = "ideation/ideation/suspend";
export const URL_IDEATION_SAVE       = "ideation/ideation/save";
export const URL_IDEATION_GET_OWN    = "ideation/ideation-list/own/";
export const URL_IDEATION_LIST_OWN   = "ideation/ideation-list/own";

export const URL_MASTER_DATA_READ    = "ideation/public/ideation-master/list";
export const URL_OWN_IDEATOR_METRICS = "ideation/user-ideation-action/ideator/metrics/own";

export const URL_MASTER_ADD_CATEGORY = "ideation/ideation-master/category"; 
export const URL_MASTER_ADD_SUBJECT  = "ideation/ideation-master/subject";

export const URL_IDEATION_IMAGE_UPLOAD="ideation/ideation/image/upload";
export const URL_IDEATOR_IDEATION_MINIMAL="ideation/ideation-list/own/active/restricted/minimal";

export const URL_PUBLIC_RANDOM_IDEATIONS=BASE_URL+"ideation/public/random";
export const URL_PUBLIC_VIEWER_IDEATION=BASE_URL+"ideation/public/viewer/ideation";
export const URL_PUBLIC_VIEWER_IDEATION_SLUG=BASE_URL+"ideation/public/viewer/ideation/slug";
export const URL_PUBLIC_VIEWER_IDEATION_SERIES_SLUG=BASE_URL+"ideation/public/ideation-series/view/slug";

export const URL_IDEATION_SERIES_GET_OWN="ideation/ideation-series/view/";
export const URL_IDEATION_SERIES_SAVE = "ideation/ideation-series/save";
export const URL_IDEATION_SERIES_LIST_OWN = "ideation/ideation-series/ideator/list/own/";
export const URL_IDEATION_SERIES_PUBLISH = "ideation/ideation-series/publish";
export const URL_IDEATION_SERIES_SUSPEND = "ideation/ideation-series/suspend";

export const URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS = "ideation/ideation-edit-request/list/author/status/requests/";
export const URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_IDEATION = "ideation/ideation-edit-request/list/author/ideation/requests/";
export const URL_IDEATION_EDIT_REQUEST_AUTHOR_CLOSE_REQUEST = "ideation/ideation-edit-request/close";

export const URL_PUBLIC_VIEWER_BASE="https://portal.microideation.com/";

export const URL_IDEATION_IMAGE_LOAD  = "https://portal.microideation.com/images/";


// Define the miAPI as axios
const miAPI = axios.create({
    baseURL: BASE_URL,
    withCredentials:true
});

// Add the interceptor for the response to handle the authentication issues
// This interceptor will check if the response status is 401 and will store the 
// current request. On 401, it will call the refresh token API and try to restore 
// the token. On success, we will post the original request again.
miAPI.interceptors.response.use(function(response) {

    // For success return the response as is
    return response;

},function(error) {

    // Log the error
    console.log("error :" + JSON.stringify(error));

    // Store the original request
    const originalReq = error.config;

    // Check if the response is having error code as 401
    // and is not a retry (to avoid infinite retries) and 
    // avoid checking on the authenticate URL as it may be due to user
    // entering wrong password.
    if ( error.response.status == 401 && 
         !originalReq._retry && 
         error.response.config.url != URL_USER_AUTHENTICATE ) {

        // Set the retry flag to true
        originalReq._retry = true;

        // Call the refresh_token API
        return axios.post("https://portal.microideation.com/gateway/api/web/refresh_token",{})
                    .then((res) =>{
                        
                        // If the response is success , then log
                        if ( res.data.status == "success") {

                            // Log the message
                            console.log("token refreshed");

                            // Return the original request. ie. retry
                            return axios(originalReq);

                        } 
                    }).catch((error) => {window.location.href="/logout/nosession"});
    }

    // Log
    console.log("Rest promise error");
    
    // If not matched , then return the error
    return Promise.reject(error);
});

export {miAPI};
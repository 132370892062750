// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  console.log("pushing " + JSON.stringify(element));
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.removeIfExist = function (comparer) {
  if (!this.inArray(comparer)) {
    return;
  }

  // Find the index
  var index = 0;

  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) index = i;
  }

  // Splice the array
  this.splice(index, 1);
};

export const IdeatorProfile = () => {
  return {
    pubIdeations: [],
  };
};

export const getIdeationEditorMentionSuggestions = (pubIdeations) => {
  return pubIdeations.map((e) => ({
    text: e.topic.toLowerCase(),
    value: e.topic.toLowerCase(),
    url: "mideation://" + e.id,
  }));
};

export const USER_SESSION_LOGIN = "USER_SESSION_LOGIN";
export const USER_SESSION_EXPIRED = "USER_SESSION_EXPIRED";
export const USER_SESSION_LOGOUT = "USER_SESSION_LOGOUT";

// Use session object
export const UserSession = () => {
  return {
    username: "",
    displayName: "",
    isActive: false,
    status: "CREATED",
    userType:"USER"
  };
};

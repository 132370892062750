import * as userSessionSpec from "../support/UserSessionSpec";

export const userSessionReducer = (
  state = userSessionSpec.UserSession(),
  action
) => {
  switch (action.type) {
    case userSessionSpec.USER_SESSION_LOGIN:
      console.log("reducer -> " + JSON.stringify(action.session));
      return { ...action.session };
    case userSessionSpec.USER_SESSION_EXPIRED:
      return userSessionSpec.UserSession();
    case userSessionSpec.USER_SESSION_LOGOUT:
      return userSessionSpec.UserSession();
    default:
      return state;
  }
};

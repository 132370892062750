import React from "react";
import { Container, Alert, Button } from "react-bootstrap";
import { useState } from "react";
import * as blActions from "../../actions/BlockingLoaderAction";
import { miAPI, URL_RESEND_ACTIVATE_LINK } from "../../support/RestAPISpec";
import { useDispatch, useSelector } from "react-redux";

export const ResendActivationLinkSection = () => {
  // Show the data
  const [show, setShow] = useState(true);
  const [content, setContent] = useState(
    "Please activate your account to create and save ideations. You can resend activation link to your registered email by clicking on the button."
  );
  const [buttonVisible, setButtonVisible] = useState(true);
  const [variant, setVariant] = useState("warning");
  const [heading, setHeading] = useState("Activation Pending");

  // Get the user session object
  const userSession = useSelector((state) => state.userSession);

  // Create dispath
  const dispatch = useDispatch();

  // Method to resent action link
  const resendActivationLink = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Requesting Link..."));

    // Create the form data
    var formData = new FormData();

    // Append the identifier
    formData.append("identifier", userSession.username);

    // Make the API call
    miAPI
      .post(URL_RESEND_ACTIVATE_LINK, formData)
      .then((result) => {
        if (result.data.status === "success") {
          setVariant("success");
          setButtonVisible(false);
          setHeading("Link Sent");
          setContent("Link successfully send to registered email account.");
        } else {
          setVariant("warning");
          setHeading("Link Failed");
          setButtonVisible(true);
          setContent("Link generation failed");
        }
      })
      .catch((error) => {
        setHeading("Link Failed");
        setContent("Link generation failed");
        setVariant("warning");
        setButtonVisible(true);
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <React.Fragment>
      {show && (
        <Alert variant={variant} dismissible onClose={() => setShow(false)}>
          <Alert.Heading>{heading}</Alert.Heading>
          <p>{content}</p>
          {buttonVisible && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => resendActivationLink()}
            >
              {" "}
              Resend Link{" "}
            </button>
          )}
        </Alert>
      )}
    </React.Fragment>
  );
};

import * as ideationSpec from "../support/IdeationSpec";

export const ideationCreateNew = () => {
  return {
    type: ideationSpec.IDEATION_NEW,
    payload: {},
  };
};

export const ideationSaved = (ideation) => {
  return {
    type: ideationSpec.IDEATION_SAVED,
    payload: ideation,
  };
};

export const ideationSuspended = (ideation) => {
  return {
    type: ideationSpec.IDEATION_SUSPENDED,
    payload: ideation,
  };
};

export const ideationPublished = (ideation) => {
  return {
    type: ideationSpec.IDEATION_PUBLISHED,
    payload: ideation,
  };
};

export const ideationEdit = (ideation) => {
  return {
    type: ideationSpec.IDEATION_EDIT,
    payload: ideation,
  };
};

export const ideationOwnListFetched = (ideationList, currPage) => {
  return {
    type: ideationSpec.IDEATION_OWN_LIST_FETCHED,
    payload: ideationList,
    currPage: currPage,
  };
};

export const ideationOwnSearchTriggered = (filter, query) => {
  return {
    type: ideationSpec.IDEATION_OWN_SEARCH_TRIGGERED,
    filter: filter,
    query: query,
    currPage: 0,
  };
};


export const ideationUpdateUnsaved = (ideation) => {
  return {
    type: ideationSpec.IDEATION_UPDATE_UNSAVED,
    payload: ideation
  };
};

import React from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// IMPORTANT : Extending the Navbar from bootstrap as we cannot use
// Styles as a separate container. That will affect the sticky top property
// if navbar is inside another div
const NavbarStyled = styled(Navbar)`
  /* Applies to the component NavbarStyled*/
  border-bottom: solid 5px #dc2430;

  .navbar-brand,
  .navbar-nav .nav-link {
    color: #000;
  }

  .dropdown a {
    font-style: italic;
  }

  &:hover {
    color: blue;
  }

  .hidden {
    display: none;
  }
`;

// If this is does not seems to be working, try passing props as argument
// or the {className} as argument
export const NavigationBar = (props) => {
  // Create the dispatch
  const dispatch = useDispatch();

  // Define the user session
  const userSession = useSelector((state) => state.userSession);

  return (
    // Use the extended styled component
    <NavbarStyled expand="lg" className="navbar-light bg-light" sticky="top">
      <Navbar.Brand href="/">
        <strong>microideation</strong>
        <sup>
          <span style={{ "font-size": 12 }}>&nbsp;beta</span>
        </sup>{" "}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Link className="nav-link" to="/">
              Home
            </Link>
          </Nav.Item>
          <NavDropdown title="Download" className={userSession.isActive ? "hidden" : ""}>
            <NavDropdown.Item href="https://apps.apple.com/us/app/microideation/id1582752866" target="_blank">Apple Appstore</NavDropdown.Item>
            <NavDropdown.Item href="https://play.google.com/store/apps/details?id=com.microideation.app" target="_blank">Google Playstore</NavDropdown.Item>
          </NavDropdown>
          <Nav.Item>
            <Link className="nav-link" to="/about">
              About
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </Nav.Item>
          <Nav.Item className={userSession.isActive ? "hidden" : ""}>
            <Link className="nav-link" to="/register">
              Register
            </Link>
          </Nav.Item>
          <Nav.Item className={userSession.isActive ? "hidden" : ""}>
            <Link className="nav-link" to="/login">
              Login
            </Link>
          </Nav.Item>
          <NavDropdown
            title={
              <span>
                <FontAwesomeIcon icon={faUser} />
                &nbsp;{userSession.username}
              </span>
            }
            id="user-dropdown"
            className={!userSession.isActive ? "hidden" : ""}
          >
            <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </NavbarStyled>
  );
};

import React, { createRef, useEffect, useState } from "react";
import "./css/OuterPage.css";
import { Link, useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { miAPI, URL_ACCOUNT_ACTIVATE } from "../../support/RestAPISpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import { ExternalCommonSection } from "./ExternalCommonSection";
import { useDispatch } from "react-redux";
import { CustomAlert } from "../common/CustomAlert";
import { Container } from "react-bootstrap";

export const AccountActivateForm = (props) => {
  // Define the code
  const [accountActivated, setAccountActivated] = useState(false);

  // Create the dispatch
  const dispatch = useDispatch();

  // Create the history
  const history = useHistory();

  // Get the id from the route params
  const { id } = useParams();

  // The alert ref
  const alertRef = React.useRef(null);

  // Check the id
  useEffect(() => {
    // Check if the id is present
    if (id) activateAccount();
    else {
      alertRef.current.showAlert({
        heading: "Account Activate",
        message: "No verification code passed.",
        variant: "warning",
      });
    }
  }, [id]);

  // Disable the loading if already in progress
  useEffect(() => {
    dispatch(blActions.blLoadingDone());
  }, []);

  // activate the account
  const activateAccount = () => {
    // Create the form data
    var formData = new FormData();

    // Append the code
    formData.append("code", id);

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Activating Account..."));

    // Make the API call
    miAPI
      .post(URL_ACCOUNT_ACTIVATE, formData)
      .then((result) => {
        if (result.data.status === "success") {
          alertRef.current.showAlert({
            heading: "Account Activate",
            message: "Account activated succesfully!",
            variant: "success",
          });
          setAccountActivated(true);
        } else {
          alertRef.current.showAlert({
            heading: "Account Activate",
            message: result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Account Activate",
          message: "Unable to activate account",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <React.Fragment>
      <div id="logreg-forms">
        {!accountActivated && (
          <React.Fragment>
            <div className="header">
              <h3 className="h3 font-weight-normal center-align">
                <strong>Account Activation</strong>
              </h3>
            </div>
            <Container>
              <CustomAlert ref={alertRef} />
              <br />
            </Container>
          </React.Fragment>
        )}
        {accountActivated && (
          <Container align="center">
            <br />
            <h4>Account Activated !!</h4>
            <p>Your account is now active.</p>
            <ExternalCommonSection />
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={(e) => history.push("/login")}
            >
              <FontAwesomeIcon icon={faSignInAlt} /> Sign In{" "}
            </button>
            <br />
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

const formatDateTime = (dateTime) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(dateTime);
};

export const StandardIdeationMasterData = () => {
  return {
    id: "master-id",
    expiry: formatDateTime(new Date().addHours(10)),
    categories: [],
    subjects: [],
    expired: true
  };
};

export const getCategoryForRef = (masterCategories, categoryRef) => {
  return masterCategories
    .filter((e) => e.categoryRef === categoryRef)
    .map((e) => e.category);
};

export const getParentCategoryOptions = (masterCategories) => {
  return masterCategories
    .filter((e) => !e.parentCategoryRef || e.parentCategoryRef === "")
    .map((e) => ({ name: e.category, value: e.categoryRef }));
};

export const getCategoryOptions = (masterCategories, parentCategoryRef) => {
  return masterCategories
    .filter(
      (e) =>
        e.parentCategoryRef &&
        e.parentCategoryRef != null &&
        e.parentCategoryRef == parentCategoryRef
    )
    .map((e) => ({
      name: e.category,
      value: e.categoryRef,
    }));
};

export const getParentCategoryRefForCategory = (
  masterCategories,
  categoryRef
) => {
  var value = masterCategories
    .filter((e) => e.categoryRef === categoryRef)
    .map((e) => e.parentCategoryRef);

  return value;
};

export const getSubjectOptions = (masterSubjects) => {
  return masterSubjects.map((e) => ({ name: e, value: e }));
};

import React, { createRef, useEffect, useState } from "react";
import "./css/OuterPage.css";

import { Container } from "react-bootstrap";
import ideaImg from "./img/ideas.png";
import { ExternalCommonSection } from "./ExternalCommonSection";
import { SocialContact } from "./SocialContact";

export const ContactUs = (props) => {
  return (
    <React.Fragment>
      <div id="logreg-forms">
        <Container align="center">
          <br />
          <h4>Contact Us!!</h4>
          <p>
            Getting in touch.
            <br />
            <br />
            <img src={ideaImg} height={200} />
          </p>
          <SocialContact />
          <br />
        </Container>
      </div>
    </React.Fragment>
  );
};

import React, { useRef, useEffect, useState } from "react";
import "./css/Home.css";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import gstoreImg from "./img/google-play-badge.svg";
import ytImg from "./img/watch-on-youtube.png";
import astoreImg from "./img/app-store-badge.svg";
import { useHistory } from "react-router-dom";
import { miAPI, URL_PUBLIC_RANDOM_IDEATIONS } from "../../support/RestAPISpec";
import Axios from "axios";
import { RandomIdeationView } from "../common/RandomIdeationView";

export const Home = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <header className="masthead">
        <div className="container h-100 mx-auto">
          <div className="row h-100">
            <div className="col-lg-8 col-md-6 my-auto">
              <div className="header-content">
                <h1 className="mb-5">
                  Create and discover{" "}
                  <b>
                    <i>micro</i> content
                  </b>{" "}
                   on topics you love
                </h1>
                <div className="badges" style={{ textAlign: "center" }}>
                  <a
                    className="badge-link"
                    href="https://play.google.com/store/apps/details?id=com.microideation.app"
                    target="_blank"
                  >
                    <img src={gstoreImg} width="240" alt="" />
                  </a>
                  &nbsp;&nbsp;
                  <a
                    className="badge-link"
                    href="https://apps.apple.com/us/app/microideation/id1582752866"
                    target="_blank"
                  >
                    <img src={astoreImg} width="240" alt="" />
                  </a>
                </div>
                <hr />
                <h3>Are you an expert in your field ? </h3>
                <p className="center">
                  <Button
                    className="mb-2 mr-2"
                    variant="primary outline"
                    onClick={() => history.push("register")}
                  >
                    Sign Up Now
                  </Button>
                  <Button
                    className="mb-2"
                    variant="outline-light"
                    onClick={() => history.push("about")}
                  >
                    Learn more..
                  </Button>
                </p>
                <p>
                  Craft beautiful single page micro content on topics under your
                  area of expertise and get recognized.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-auto mx-auto">
              <div className="device-container">
                <div className="device-mockup iphone6_plus portrait white">
                  <div className="device">
                    <div className="screen">
                      <RandomIdeationView />
                    </div>
                    <div className="button"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export const SD_SHOW = "SD_SHOW";
export const SD_CLOSE = "SD_CLOSE";

export const SD_TYPE_IDEATION = "ideation";
export const SD_TYPE_SERIES = "series";

export const showShareDialog = () => {
  return {
    type: SD_SHOW,
  };
};

export const closeShareDialog = () => {
  return {
    type: SD_CLOSE,
  };
};

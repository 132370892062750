import {
  faEdit,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Accordion, Alert, Badge, Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as blActions from "../actions/BlockingLoaderAction";
import {
  refreshAuthorClosedIdeationEdits,
  refreshAuthorOpenIdeationEdits,
  updateAuthorClosedIdeationEdits,
  updateAuthorOpenIdeationEdits,
} from "../actions/IdeationEditRequestActions";
import {
  IER_STATUS_CLOSED,
  IER_STATUS_OPEN,
  IER_VIEW_TYPE_IDEATION,
} from "../support/IdeationEditRequestSpec";
import {
  miAPI,
  URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_IDEATION,
  URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS,
} from "../support/RestAPISpec";
import { IdeationeditRequestListItem } from "./IdeationEditRequestListItem";
export const IdeationEditRequestListForStatus = (props) => {
  // Create dispatch
  const dispatch = useDispatch();

  // Call the useEffect to load
  useEffect(() => {
    if (
      props.editRequestsForStatus.list.length === 0 &&
      props.editRequestsForStatus.endOfList === false
    ) {
      console.log("useEffect for status => Fetching");
      fetchAuthorIdeationEditRequestsForStatus();
    }
  }, [props.editRequestsForStatus.refreshId]);

  // Process the status list result and populate the information
  const processIdeationEditRequestsResult = (result, currPage) => {
    // Define an list end flag
    let endOfList = false;

    if (result.data.status === "success") {
      // if the page + 1 is matching total pages, then set end of list of true
      if (parseInt(result.data.pagenumber) + 1 >= result.data.totalpages) {
        endOfList = true;
      }

      // store the next page ( set as same page number when no data, else increment )
      let nextPage = result.data.data.length === 0 ? currPage : currPage + 1;

      // Check the status and dispatch
      if (props.status === IER_STATUS_OPEN)
        dispatch(
          updateAuthorOpenIdeationEdits(result.data.data, nextPage, endOfList)
        );
      else
        dispatch(
          updateAuthorClosedIdeationEdits(result.data.data, nextPage, endOfList)
        );
    } else {
      props.alertRef.current.showAlert({
        heading: "Ideation Edits Fetch",
        message: "Error retrieving ideations edits: \n" + result.data.errordesc,
        variant: "warning",
      });
    }
  };

  // Build and return the URL based on the type
  const getReadIdeationUrl = () => {
    let URL =
      props.viewType && props.viewType === IER_VIEW_TYPE_IDEATION
        ? URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_IDEATION +
          props.ideationId +
          "/" +
          props.status
        : URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS + props.status;
    return URL;
  };

  const getSortField = () => {
    return props.status === IER_STATUS_OPEN
      ? "createdDateTime,desc"
      : "closedDateTime,desc";
  };

  // Read the ideations edit requests for next page
  const fetchAuthorIdeationEditRequestsForStatus = (isRefresh) => {
    // show the loader
    dispatch(blActions.blLoadingInProgress("Loading edit requests"));

    // Set the currPage
    let currPage = !isRefresh ? props.editRequestsForStatus.currPage : 0;

    // If page is not specified, then set to 0
    miAPI
      .get(
        getReadIdeationUrl() +
          "?page=" +
          currPage +
          "&size=3&sort=" +
          getSortField()
      )
      .then((result) => {
        processIdeationEditRequestsResult(result, currPage);
      })
      .catch((error) => {
        props.alertRef.current.showAlert({
          heading: "Ideation Edit Requests Fetch",
          message: "Error retrieving ideation edit requests",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  const onRefresh = () => {
    // Check the status and then act
    if (props.status === IER_STATUS_OPEN) {
      dispatch(refreshAuthorOpenIdeationEdits());
    } else if (props.status === IER_STATUS_CLOSED) {
      dispatch(refreshAuthorClosedIdeationEdits());
    }
  };

  const renderIdeationEditRequest = (entry) => (
    <IdeationeditRequestListItem entry={entry} mainAlertRef={props.alertRef} />
  );

  const getInfoText = () => {
    if (props.status === IER_STATUS_OPEN)
      return "The OPEN edit requests from your readers. Please go through them and make changes to your ideations if necessary and close with comments.";
    else if (props.status === IER_STATUS_CLOSED)
      return "Requests that you have CLOSED. You may view what you have commented. No further edits are allowed.";
  };
  return props.compactMode ? (
    props.ideationId !== "" && props.editRequestsForStatus.list.length > 0 && (
      <Alert variant="info">
        <Row>
          <Col md={6}>
            <FontAwesomeIcon icon={faEdit} />
            &nbsp;
            <strong>Edit requests</strong>
          </Col>
          <Col md={6} align="right">
            <a
              href={"/portal/ideation-edit-requests/" + props.ideationId}
              target="_blank"
            >
              View Requests
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className="mb-1 mt-1" />
            You have{" "}
            <Badge variant="success">
              {props.editRequestsForStatus.list.length}
            </Badge>
            &nbsp;open edit requests received from viewers. Click{" "}
            <i>
              <b>'View Requests'</b>
            </i>{" "}
            to see them and close with comments.
          </Col>
        </Row>
      </Alert>
    )
  ) : (
    <>
      <Alert variant="secondary">
        <Row>
          <Col className="align-self-center d-none d-md-block">
            <FontAwesomeIcon icon={faEdit} size="2x" />
          </Col>
          <Col md={9} className="align-self-center">
            {getInfoText()}
          </Col>
          <Col md={2} className="align-self-center">
            <Button variant="success" onClick={() => onRefresh()} block>
              Refresh
            </Button>
          </Col>
        </Row>
      </Alert>
      {props.editRequestsForStatus.list.length === 0 && (
        <Row>
          <Col align="center" md={{ span: 6, offset: 3 }}>
            <h3>No {props.status} edit requests found!!</h3>
          </Col>
        </Row>
      )}
      <Accordion defaultActiveKey="0" flush>
        {props.editRequestsForStatus.list.map((entry) =>
          renderIdeationEditRequest(entry)
        )}
      </Accordion>
      <Row>
        <Col align="center">
          {!props.editRequestsForStatus.endOfList && (
            <a
              href="#"
              onClick={() => fetchAuthorIdeationEditRequestsForStatus()}
            >
              <p className="mb-3 mt-3">Load More</p>
            </a>
          )}
          {props.editRequestsForStatus.endOfList &&
            props.editRequestsForStatus.list.length > 0 && (
              <p className="mb-3 mt-3">No more {props.status} items!!</p>
            )}
        </Col>
      </Row>
    </>
  );
};

import React, { useState, useEffect } from "react";
import {
  faUserPlus,
  faAngleLeft,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./css/OuterPage.css";
import { CustomAlert } from "../common/CustomAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { ExternalCommonSection } from "./ExternalCommonSection";
import { miAPI, URL_REGISTER_USER } from "../../support/RestAPISpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import ideaImg from "./img/ideas.png";

export const RegisterForm = (props) => {
  // Storing the register object
  const [userRegisterObj, setUserRegisterObj] = useState({});
  const [registered, setRegistered] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Get reference to history
  const history = useHistory();

  // Get the alert
  const alertRef = React.useRef(null);

  // Get the dispather
  const dispatch = useDispatch();

  // Text content change handler
  const handleChange = (event) => {
    setUserRegisterObj({
      ...userRegisterObj,
      [event.target.name]: event.target.value,
    });
  };

  // Disable the loading if already in progress
  useEffect(() => {
    dispatch(blActions.blLoadingDone());
  }, []);

  // Call the method to register the user
  const registerUser = (event) => {
    // Prevent the default action
    event.preventDefault();

    // Clear the alert
    alertRef.current.clear();

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Registration in progress..."));

    // Call the service
    miAPI
      .post(URL_REGISTER_USER, { ...userRegisterObj, registerPlatform: "web" })
      .then((result) => {
        console.log("Result is -> " + JSON.stringify(result));
        if (result.data.status === "success") {
          alertRef.current.showAlert({
            heading: "Registration",
            message: "Registered successfully",
            variant: "success",
          });
          setRegistered(true);
        } else {
          alertRef.current.showAlert({
            heading: "Registration",
            message: result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Registration",
          message: "Error registering user",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <React.Fragment>
      <div id="logreg-forms">
        {!registered && (
          <React.Fragment>
            <div className="header">
              <h1 className="h3 font-weight-normal center-align">
                <strong>Register</strong>
              </h1>
              <h5 className="font-weight-normal center-align font-weight-normal h5">
                Create and discover micro ideas
              </h5>
            </div>

            <form
              className="form-signup"
              onSubmit={(event) => registerUser(event)}
            >
              <ExternalCommonSection />
              <CustomAlert ref={alertRef} />
              <h5 className="m3-b">Let's get started..</h5>
              <input
                type="text"
                id="username"
                className="form-control dissolved-textfield-top"
                placeholder="Username"
                required=""
                autoFocus
                name="username"
                value={userRegisterObj.username}
                onInput={(e) => handleChange(e)}
              />
              <input
                type="email"
                id="useremail"
                className="form-control dissolved-textfield-middle"
                placeholder="Email address"
                required
                name="email"
                value={userRegisterObj.email}
                onInput={(e) => handleChange(e)}
              />
              <input
                type="password"
                id="userpass"
                className="form-control dissolved-textfield-middle"
                placeholder="Password"
                required
                name="password"
                value={userRegisterObj.password}
                onInput={(e) => handleChange(e)}
              />
              <input
                type="password"
                id="user-repeatpass"
                className="form-control dissolved-textfield-bottom"
                placeholder="Repeat Password"
                required
                name="rePassword"
                value={userRegisterObj.rePassword}
                onInput={(e) => handleChange(e)}
              />
              <div className="accept-checkbox">
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />{" "}
                I accept the{" "}
                <a
                  href="https://portal.microideation.com/terms-and-conditions"
                  target="blank"
                >
                  TnC
                </a>
                &nbsp; , &nbsp;
                <a
                  href="https://portal.microideation.com/privacy-policy"
                  target="blank"
                >
                  Privacy Policy
                </a>
              </div>
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={!termsAccepted}
              >
                <FontAwesomeIcon icon={faUserPlus} /> Sign Up
              </button>
              <Link to="/login">
                <FontAwesomeIcon icon={faAngleLeft} /> Back
              </Link>
            </form>
          </React.Fragment>
        )}
        {registered && (
          <Container align="center">
            <br />
            <h4>Congratulations!!</h4>
            <p>
              You are now successfully registered. Login and start ideating!!.
              <br />
              <br />
              <img src={ideaImg} height={200} />
            </p>
            <ExternalCommonSection />
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={(e) => history.push("/login")}
            >
              <FontAwesomeIcon icon={faSignInAlt} /> Sign In{" "}
            </button>
            <br />
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

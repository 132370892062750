import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Home } from './components/outer/Home';
import { About } from './components/outer/About';
import { NotFound } from './components/common/NotFound';
import { LoginForm } from './components/outer/LoginForm';
import { Layout } from './components/common/Layout';
import {NavigationBar} from './components/common/NavigationBar';
import {ResetPasswordRequestForm} from './components/outer/ResetPasswordRequestForm';
import {ResetPasswordProcessForm} from './components/outer/ResetPasswordProcessForm';
import {RegisterForm} from './components/outer/RegisterForm';
import {LogoutForm} from './components/outer/LogoutForm';
import {PortalHome} from './components/portal/PortalHome';
import {Footer} from './components/outer/Footer';
import {AccountActivateForm} from './components/outer/AccountActivateForm';
import {BlockingLoader} from './components/common/BlockingLoader';
import { ContactUs } from './components/outer/ContactUs';
import { PrivacyPolicy } from './components/outer/privacy-policy';
import { TermsAndConditions } from './components/outer/tnc';
import { useSelector } from 'react-redux';
import { IdeationViewer } from './components/outer/IdeationViewer';
import { IdeationSeriesViewer } from './components/outer/IdeationSeriesViewer';

function App() {

   // Get the userSession
   const userSession = useSelector((state) => state.userSession);

  return (
    <React.Fragment>          
        <Router>          
          <NavigationBar/>
          <Layout>
            {/*This is the dynamic content area*/ }
            <Switch>
                   
              <NoAuthRoute exact path="/"><Home/></NoAuthRoute>
              <NoAuthRoute path="/login"><LoginForm/></NoAuthRoute>
              <NoAuthRoute path="/reset-password"><ResetPasswordRequestForm/></NoAuthRoute>
              <NoAuthRoute path="/reset-password-process/:id"><ResetPasswordProcessForm/></NoAuthRoute>
              <NoAuthRoute path="/register"><RegisterForm/></NoAuthRoute>
              
         
              <AuthRoute path="/portal"><PortalHome/></AuthRoute>

              <Route path="/account-activate/:id" component={AccountActivateForm} />              
              <Route path="/about" component={About} />              
              <Route path="/contact" component={ContactUs} />                                              
              <Route path="/logout" component={LogoutForm} />
              <Route path="/logout/nosession" component={LogoutForm} />              
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route path="/viewer/ideation/:id"><IdeationViewer/></Route>
              <Route path="/viewer/slug/ideation/:slug"><IdeationViewer/></Route>
              <Route path="/viewer/ideation-series/:slug"><IdeationSeriesViewer/></Route>
              
              <Route component={NotFound} />
            </Switch>
            <BlockingLoader/>
          </Layout>          
          <Footer/>          
        </Router>    
    </React.Fragment>
  );

  {/** 
     * Routes that are used when customer is not logged in ( login, register etc)
     * Checks session and if already logged in ,redirect to portal 
     */}
  function NoAuthRoute({children,...props}) {
    return(
      <Route {...props} render={({location}) => (
        userSession.isActive ? (<Redirect to="/portal"/>):(children)
      )}/>
    )
  }

  {/* Routes that are used only when user has a session
    * Checks session and if not logged in, then redirect to login 
    */}
  function AuthRoute({children,...props}) {
    return(
      <Route {...props} render={({location}) => (
        !userSession.isActive ? (<Redirect to="/login"/>):(children)
      )}/>
    )
  }
}

export default App;

import React from "react";
import "./css/Ideation.css";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faHeart,
  faThumbsUp,
  faThumbsDown,
  faBullhorn,
  faBullseye,
  faUser,
  faSuperscript,
} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import { Ideation } from "../../support/IdeationSpec";

export class IdeationPreview extends React.Component {
  state = Ideation();

  setAuthor = (author) => {
    this.state.author = author;
    this.setState(this.state);
  };

  setContent = (content) => {
    this.state.content = content;
    this.setState(this.state);
  };

  setTopic = (topic) => {
    this.state.topic = topic;
    this.setState(this.state);
  };

  setCategory = (category) => {
    this.state.category = category;
    this.setState(this.state);
  };

  setSubject = (subject) => {
    this.state.subject = subject;
    this.setState(this.state);
  };

  clearView = () => {
    this.state.content = "";
    this.state.tags = "";
    this.state.category = "";
    this.state.topic = "";
    this.setState(this.state);
  };

  limitedString = (str, len) => {
    if (new String(str).length > len)
      return new String(str).substring(0, len) + "..";
    else return str;
  };

  render() {
    return (
      <div className="ideation_viewport">
        <Container>
          <Row className="align-items-center">
            <Col
              md={7}
              sm={7}
              xs={7}
              style={{ paddingRight: 0 }}
              className="ideation_topic"
            >
              <h5
                style={{ marginBottom: 0 }}
                data-toggle="tooltip"
                title={this.state.topic}
              >
                {this.limitedString(this.state.topic, 14)}
              </h5>
            </Col>
            <Col md={5} sm={5} xs={5} align="right">
              <div className="ideation-field_text">
                <Badge
                  pill
                  variant="warning"
                  data-toggle="tooltip"
                  title={this.state.category}
                >
                  {this.limitedString(this.state.category, 10)}
                </Badge>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="ideation-field_text">
                <b>on</b> <i>{this.limitedString(this.state.subject, 16)}</i> ,{" "}
                <b>by</b> <i>{this.limitedString(this.state.author, 12)}</i>
              </div>
            </Col>
          </Row>
          <Row className="ideation-margin-top-10px">
            <Col>
              <div className="ideation_content">
                {parse(this.state.content)}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="ideation_bottom_controls">
          {this.props.source === "viewer" ? (
            <Row>
              <Col md={12} sm={12} align="center" className="mt-1">
                &nbsp;
                <a
                  className="badge-link ideation-a-href"
                  href="https://play.google.com/store/apps/details?id=com.microideation.app"
                  target="_blank"
                >
                  DOWNLOAD APP
                </a>
                &nbsp; | &nbsp;
                <a
                  className="badge-link ideation-a-href"
                  href="https://portal.microideation.com/about"
                  target="_blank"
                >
                  ABOUT
                </a>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col align="center">
                <FontAwesomeIcon icon={faHeart} color="#fff" size="1x" />
              </Col>
              <Col align="center">
                <FontAwesomeIcon icon={faThumbsUp} color="#fff" />
              </Col>
              <Col align="center">
                <FontAwesomeIcon icon={faThumbsDown} color="#fff" />
              </Col>
              <Col align="center">
                <FontAwesomeIcon icon={faListUl} color="#fff" />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

import React, { useState, useRef, useEffect } from "react";
import "./css/OwnIdeationList.css";
import {
  miAPI,
  URL_IDEATION_LIST_OWN,
  URL_IDEATION_SERIES_LIST_OWN,
} from "../../support/RestAPISpec";
import { CustomAlert } from "../common/CustomAlert";
import {
  Container,
  Row,
  Col,
  Button,
  FormControl,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  faThumbsUp,
  faSync,
  faBullseye,
  faHeart,
  faShareAlt,
  faLightbulb,
  faTimes,
  faSearch,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import {
  ideationSeriesOwnListFetched,
  ideationSeriesOwnSearchTriggered,
} from "../../actions/IdeationSeriesAction";
import { useSelector, useDispatch } from "react-redux";
import { IdeationIntro } from "./IdeationIntro";
import * as blActions from "../../actions/BlockingLoaderAction";
import { ShareDialog } from "../common/ShareDialog";
import * as masterDataSpec from "../../support/MasterDataSpec";
import { Badge } from "react-bootstrap";
import * as sdActions from "../../actions/ShareDialogActions";

export const OwnIdeationSeriesListForm = (props) => {
  // Constants
  const [endOfList, setEndOfList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [shareSeries, setShareSeries] = useState({});

  // Reference to the custom alert
  const alertRef = useRef();

  // Reference to the history
  const history = useHistory();

  // Get the idSeriesList
  const idSeriesList = useSelector((state) => state.ideationSeries.list);

  // Get the page
  const currPage = useSelector((state) => state.ideationSeries.currPage);

  // Get the filter
  const filter = useSelector((state) => state.ideationSeries.filter);

  // Get the query
  const query = useSelector((state) => state.ideationSeries.query);

  // Get the masterData
  const masterData = useSelector((state) => state.masterData);

  // Create dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    // Set the search query as the query from the state if its not default
    if (filter != "all") setSearchQuery(query);
  }, []);

  // Trigger search on filter change
  useEffect(() => {
    fetchOwnIdeationSeries();
  }, [filter]);

  // Method to reset the search
  const resetSearch = () => {
    dispatch(ideationSeriesOwnSearchTriggered("all", "all"));
    setSearchQuery("");
  };

  // Method to trigger the search
  const triggerSearch = (filter) => {
    // Dispatch the search
    dispatch(ideationSeriesOwnSearchTriggered(filter, searchQuery));
  };

  // Read the own ideations for next page
  const fetchOwnIdeationSeries = () => {
    // show the loader
    dispatch(blActions.blLoadingInProgress("Loading series"));

    // If page is not specified, then set to 0
    miAPI
      .get(
        URL_IDEATION_SERIES_LIST_OWN +
          "/" +
          filter +
          "/" +
          query +
          "?page=" +
          currPage +
          "&size=10&sort=createdAt,desc"
      )
      .then((result) => {
        // Check the status
        if (result.data.status === "success") {
          // if the page + 1 is matching total pages, then set end of list of true
          if (parseInt(result.data.pagenumber) + 1 >= result.data.totalpages) {
            setEndOfList(true);
          } else {
            setEndOfList(false);
          }
          dispatch(
            ideationSeriesOwnListFetched(result.data.data, currPage + 1)
          );
        } else {
          alertRef.current.showAlert({
            heading: "Series Fetch",
            message: "Error retrieving series : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Series Fetch",
          message: "Error retrieving series",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <div className="section_container content_section">
      <ShareDialog item={shareSeries} type={sdActions.SD_TYPE_SERIES} />
      <div className="content_header">
        <Row>
          <Col md={12}>
            <div className="content_header_heading">
              <h5>My Ideation Series</h5>
            </div>
            <div className="content_header_controls">
              <Row>
                <Col md={4}>
                  <Button
                    variant="success"
                    onClick={() => history.push("/portal/ideation-series-form")}
                    block
                  >
                    Create New
                  </Button>
                </Col>
                <Col md={{ span: 8 }}>
                  <InputGroup className="">
                    <InputGroup.Prepend>
                      <Button onClick={() => resetSearch()} variant="primary">
                        {searchQuery !== "" ? (
                          <FontAwesomeIcon icon={faTimes} />
                        ) : (
                          <FontAwesomeIcon icon={faSearch} />
                        )}
                      </Button>
                    </InputGroup.Prepend>

                    <FormControl
                      placeholder="Search text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(event) =>
                        event.key === "Enter" ? triggerSearch("name") : null
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        onClick={() => triggerSearch("name")}
                        variant="primary"
                      >
                        Search
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content_section_form">
        <Container>
          <CustomAlert ref={alertRef} />
        </Container>
        <Row>
          <Col>
            <Container>
              {idSeriesList.length === 0 && (
                <Row>
                  <Col align="center" md={{ span: 6, offset: 3 }}>
                    <h3>No series found!!</h3>
                    <br />
                    <p>
                      <FontAwesomeIcon icon={faWindowRestore} size="3x" />
                    </p>
                    <h4>Create a series</h4>
                    <p>
                      Group related ideations into a <b>series</b> that can be
                      viewed by user in the same order.
                    </p>

                    <Button
                      onClick={() =>
                        history.push("/portal/ideation-series-form")
                      }
                    >
                      Create Series
                    </Button>
                  </Col>
                </Row>
              )}
              {idSeriesList.map((ideationSeries) => (
                <Row
                  className="ideation-item align-items-center"
                  key={ideationSeries.id}
                >
                  <Col md={4}>
                    <p>
                      {ideationSeries.name}
                      <br />
                      <span className="ideation-subject-para">
                        <Badge
                          pill
                          variant="warning"
                          data-toggle="tooltip"
                          title="Category"
                          style={{ fontSize: "14px" }}
                        >
                          {masterDataSpec.getCategoryForRef(
                            masterData.categories,
                            ideationSeries.categoryRef
                          )}
                        </Badge>
                      </span>
                    </p>
                  </Col>
                  <Col md={1}>
                    {ideationSeries.status === "PUBLISHED" && (
                      <FontAwesomeIcon
                        data-toggle="tooltip"
                        title="Share Series"
                        className="ideation_list_form_icon"
                        icon={faShareAlt}
                        size="2x"
                        onClick={() => {
                          setShareSeries(ideationSeries);
                          dispatch(sdActions.showShareDialog());
                        }}
                      />
                    )}
                  </Col>
                  <Col md={3}>
                    <span className={ideationSeries.status.toLowerCase()}>
                      {ideationSeries.status}
                      <br />
                      <span className="ideation-subject-para">
                        {ideationSeries.status !== "PUBLISHED" &&
                          ideationSeries.createdDate && (
                            <p>
                              Created : &nbsp;
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }).format(new Date(ideationSeries.createdAt))}
                            </p>
                          )}
                        {ideationSeries.status === "PUBLISHED" &&
                          ideationSeries.publishAt && (
                            <p>
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }).format(
                                new Date(Date.parse(ideationSeries.publishAt))
                              )}
                            </p>
                          )}
                      </span>
                    </span>
                  </Col>
                  <Col md={2}>
                    <Badge
                      pill
                      variant="success"
                      data-toggle="tooltip"
                      title="Total ideations"
                      style={{ fontSize: "14px" }}
                    >
                      No. of Ideations: {ideationSeries.ideations.length}
                    </Badge>
                  </Col>
                  <Col md={2}>
                    <Button
                      block
                      size="sm"
                      onClick={() =>
                        history.push(
                          "/portal/ideation-series-form/" + ideationSeries.id
                        )
                      }
                    >
                      Edit / View
                    </Button>
                  </Col>
                </Row>
              ))}
            </Container>
            <Container>
              <Row>
                <Col align="center">
                  {!endOfList && (
                    <a href="#" onClick={() => fetchOwnIdeationSeries()}>
                      <p>Load More</p>
                    </a>
                  )}
                  {endOfList && idSeriesList.length > 0 && (
                    <p>No more series !!</p>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};

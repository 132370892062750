export const ACTION_DISCARD_UNSAVED_ITEM = "DISCARD_UNSAVED_ITEM";

export const UNSAVED_TYPE_IDEATION = "ideation";
export const UNSAVED_TYPE_SERIES = "series";

export const getUnsavedEntry = (list, unsavedId) => {
  return Object.values(list).find((item) => item.unsavedId === unsavedId);
};

export const getUnsavedEntries = (list) => {
  return Object.values(list).filter((item) => item.unSavedStatus !== "saved");
};

export const formatLastSavedTimestamp = (timestamp) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(timestamp));
};

import * as ierSpec from "../support/IdeationEditRequestSpec";

export const ideationEditRequestReducer = (
  state = ierSpec.emptyIdeationEditRequestView(),
  action
) => {
  // Create a copy of state
  const newState = Object.assign({}, state);

  const getUpdatedList = (list, action) => {
    const idxIndex = list.findIndex(
      (entry) => entry.editRequest.id === action.payload.id
    );

    if (idxIndex >= 0) {
      const listCopy = [...list];
      listCopy[idxIndex].editRequest = action.payload;
      return listCopy;
    } else {
      // Insert / concat the entry at the beginning of list
      const newList = [{ editRequest: action.payload }].concat(list);
      return newList;
    }
  };

  switch (action.type) {
    case ierSpec.IER_ACTION_LOAD_USER_IDEATION_EDITS:
      return newState;

    case ierSpec.IER_ACTION_UPDATE_AUTHOR_OPEN_IDEATION_EDITS:
      newState.author.open.list = newState.author.open.list.concat([
        ...action.payload.editRequests,
      ]);
      newState.author.open.currPage = action.payload.currPage;
      newState.author.open.endOfList = action.payload.endOfList;
      return newState;

    case ierSpec.IER_ACTION_UPDATE_AUTHOR_CLOSED_IDEATION_EDITS:
      newState.author.closed.list = newState.author.closed.list.concat([
        ...action.payload.editRequests,
      ]);
      newState.author.closed.currPage = action.payload.currPage;
      newState.author.closed.endOfList = action.payload.endOfList;
      return newState;

    case ierSpec.IER_ACTION_REFRESH_AUTHOR_OPEN_IDEATION_EDITS:
      newState.author.open = ierSpec.AuthorIdeationEditRequestStatusView();
      return newState;

    case ierSpec.IER_ACTION_REFRESH_AUTHOR_CLOSED_IDEATION_EDITS:
      newState.author.closed = ierSpec.AuthorIdeationEditRequestStatusView();
      return newState;

    case ierSpec.IER_ACTION_SET_EDIT_REQUEST_VIEW_TYPE:
      newState.author.open = ierSpec.AuthorIdeationEditRequestStatusView();
      newState.author.closed = ierSpec.AuthorIdeationEditRequestStatusView();
      newState.author.viewType = action.payload.viewType;
      newState.author.ideationId = action.payload.ideationId;
      newState.author.refreshId = new Date();
      return newState;

    case ierSpec.IER_ACTION_CLOSE_IDEATION_EDIT_REQUEST:
      console.log("Open id = " + JSON.stringify(action));
      const idxIndex = newState.author.open.list.findIndex(
        (entry) => entry.editRequest.id === action.payload.id
      );

      console.log("index on open is " + idxIndex);
      // Check if the index is found
      if (idxIndex >= 0) {
        console.log("Setting entry");
        let item = newState.author.open.list[idxIndex];
        item.editRequest = { ...action.payload };
        newState.author.open.list.splice(idxIndex, 1);
        newState.author.closed.list = [{ ...item }].concat(
          newState.author.closed.list
        );
        console.log("Updated entry");
      }
      return newState;

    default:
      return state;
  }
};

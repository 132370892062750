export const IER_ACTION_LOAD_USER_IDEATION_EDITS =
  "ACTION_LOAD_USER_IDEATION_EDITS";
export const IER_ACTION_UPDATE_AUTHOR_OPEN_IDEATION_EDITS =
  "ACTION_UPDATE_AUTHOR_OPEN_IDEATION_EDITS";
export const IER_ACTION_UPDATE_AUTHOR_CLOSED_IDEATION_EDITS =
  "ACTION_UPDATE_AUTHOR_CLOSED_IDEATION_EDITS";
export const IER_ACTION_CLOSE_IDEATION_EDIT_REQUEST =
  "ACTION_CLOSE_IDEATION_EDIT_REQUEST";
export const IER_ACTION_REFRESH_AUTHOR_OPEN_IDEATION_EDITS =
  "ACTION_CLEAR_AUTHOR_OPEN_IDEATION_EDITS";
export const IER_ACTION_REFRESH_AUTHOR_CLOSED_IDEATION_EDITS =
  "ACTION_CLEAR_AUTHOR_CLOSED_IDEATION_EDITS";
export const IER_ACTION_SET_EDIT_REQUEST_VIEW_TYPE =
  "ACTION_SET_EDIT_REQUEST_VIEW_TYPE";

export const IER_STATUS_OPEN = "OPEN";
export const IER_STATUS_CLOSED = "CLOSED";

export const IER_VIEW_TYPE_ALL = "ALL";
export const IER_VIEW_TYPE_IDEATION = "IDEATION";

export const emptyIdeationEditRequestView = () => {
  return {
    author: {
      open: AuthorIdeationEditRequestStatusView(),
      closed: AuthorIdeationEditRequestStatusView(),
      viewType: IER_VIEW_TYPE_ALL,
      ideationId: "",
    },
  };
};

export const AuthorIdeationEditRequestStatusView = () => {
  return {
    list: [],
    currPage: 0,
    endOfList: false,
    refreshId: new Date(),
  };
};

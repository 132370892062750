import * as unsavedSpec from "../support/UnsavedSpec";

export const discardUnsavedItem = (unsavedId, listType) => {
  return {
    type: unsavedSpec.ACTION_DISCARD_UNSAVED_ITEM,
    payload: {
      listType: listType,
      unsavedId: unsavedId,
    },
  };
};

export const IDEATION_SERIES_NEW = "IDEATION_SERIES_NEW";
export const IDEATION_SERIES_PUBLISHED = "IDEATION_SERIES_PUBLISHED";
export const IDEATION_SERIES_SUSPENDED = "IDEATION_SERIES_SUSPENDED";
export const IDEATION_SERIES_EDIT = "IDEATION_SERIES_EDIT";
export const IDEATION_SERIES_SAVED = "IDEATION_SERIES_SAVED";
export const IDEATION_SERIES_OWN_LIST_FETCHED = "IDEATION_SERIES_OWN_LIST_FETCHED";
export const IDEATION_SERIES_OWN_SEARCH_TRIGGERED = "IDEATION_SERIES_OWN_SEARCH_TRIGGERED";


export const IdeationSeries = () => {
  return {
    id: null,
    name: "",
    desc: "",
    categoryRef: "",
    author: "",
    status: "NEW",
    publishAt: "",
    createdAt: "",
    updatedAt: "",
    ideations: [],
  };
};

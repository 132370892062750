import * as idSeriesSpec from "../support/IdeationSeriesSpec";

export const ideationSeriesCreateNew = () => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_NEW,
    payload: {},
  };
};

export const ideationSeriesSaved = (ideationSeries) => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_SAVED,
    payload: ideationSeries,
  };
};

export const ideationSeriesSuspended = (ideationSeries) => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_SUSPENDED,
    payload: ideationSeries,
  };
};

export const ideationSeriesPublished = (ideationSeries) => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_PUBLISHED,
    payload: ideationSeries,
  };
};

export const ideationSeriesEdit = (ideationSeries) => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_EDIT,
    payload: ideationSeries,
  };
};

export const ideationSeriesOwnListFetched = (ideationSeriesList, currPage) => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_OWN_LIST_FETCHED,
    payload: ideationSeriesList,
    currPage: currPage,
  };
};

export const ideationSeriesOwnSearchTriggered = (filter, query) => {
  return {
    type: idSeriesSpec.IDEATION_SERIES_OWN_SEARCH_TRIGGERED,
    filter: filter,
    query: query,
    currPage: 0,
  };
};

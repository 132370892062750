import React, { createRef, useState, useEffect } from "react";
import { CustomAlert } from "../common/CustomAlert";
import "./css/OuterPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faSignInAlt,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { UserSession, USER_SESSION_LOGIN } from "../../support/UserSessionSpec";
import {
  userSessionLoggedIn,
  userSessionLogout,
} from "../../actions/UserSessionAction";
import { useDispatch, useSelector } from "react-redux";
import { ExternalCommonSection } from "./ExternalCommonSection";
import { miAPI, URL_USER_AUTHENTICATE } from "../../support/RestAPISpec";
import { useHistory } from "react-router-dom";
import * as blActions from "../../actions/BlockingLoaderAction";

export const LoginForm = (props) => {
  // IMPORTANT : This need to be defined as useRef instead of
  // createRef.
  // Otherwise, we will loose the reference as null when we call
  // dispatch or setState method ( like setUsername ) etc which causes
  // re-render and loses the ref.
  const alertRef = React.useRef(null);

  // Create the dispatch
  const dispatch = useDispatch();

  // Create the history
  const history = useHistory();

  // Define the user session
  const userSession = useSelector((state) => state.userSession);

  // Define the constants with state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btnName, setBtnName] = useState("Sign In");
  const [btnState, setBtnState] = useState(false);

  // // Needto set the body bg required for the outer pages in individual
  // // components as otherwise,the inner pages also follows the same style
  // // as the components are rendered as child elements to already existing
  // // body in ( src/index.html )
  // useEffect(() => {

  //     // Add class on load
  //     document.body.classList.add("outer-body-bg");

  //     // cleanup
  //     return () => {
  //         document.body.classList.remove("outer-body-bg");
  //     }
  // }, [])

  // Disable the loading if already in progress
  useEffect(() => {
    dispatch(blActions.blLoadingDone());
  }, []);

  // Redirect to the portal after 3 seconds
  const redirectToPortal = () => {
    setTimeout(() => {
      history.push("/portal");
    }, 500);
  };

  // Method handling the submit event for the login form
  const handleClick = (event) => {
    // Prevent the default
    event.preventDefault();

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Authenticating..."));

    // Set the btn name as signing in
    setBtnName("Signing in...");
    setBtnState(!false);

    // Declare the session
    let session = UserSession();

    // Set the username in session
    session.username = username;

    // Place the API call to the authenticate API
    miAPI
      .post(URL_USER_AUTHENTICATE, { username: username, password: password })
      .then((result) => {
        console.log("Result is " + JSON.stringify(result));

        // Set the session to active
        session.isActive = true;

        // Set the status
        session.status = result.data.data.IAF3;

        // Set the userType
        session.userType = result.data.data.IAF2;

        // Set the method as redirecting
        setBtnName("Redirecting...");

        // IMPORTANT: This need to be called before dispatch to avoid getting Uncaught
        // exception to error block
        alertRef.current.showAlert({
          heading: "Login",
          message: "Authentication successful !!",
          variant: "success",
        });

        // Redirect to portal
        redirectToPortal();

        // dISPATCH
        dispatch(userSessionLoggedIn(session));
      })
      .catch((error) => {
        // Set the btn name as signing in
        setBtnName("Sign In");
        setBtnState(false);

        // Get the message
        let message = "Unable to contact the services. Please try again later.";

        // Check the error code
        if (error.response.data.errordesc)
          message = error.response.data.errordesc;

        // Show the alert for the failure
        alertRef.current.showAlert({
          heading: "Login",
          message,
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <React.Fragment>
      <div id="logreg-forms">
        <div className="header">
          <h1 className="h3 font-weight-normal center-align">
            <strong>Sign-in</strong>
          </h1>
          <h5 className="font-weight-normal center-align font-weight-normal h5">
            Create and discover micro ideas!!
          </h5>
        </div>
        <form className="form-signin" onSubmit={(e) => handleClick(e)}>
          <ExternalCommonSection />
          <CustomAlert ref={alertRef} />
          <input
            type="text"
            id="username"
            className="form-control"
            placeholder="Email address"
            required=""
            autoFocus
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            required=""
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="btn btn-success btn-block"
            type="submit"
            disabled={btnState}
          >
            <FontAwesomeIcon icon={faSignInAlt} />
            &nbsp;{btnName}
          </button>
          <Link to="/reset-password">Forgot Password?</Link>
          <hr />

          <button
            className="btn btn-primary btn-block"
            type="button"
            id="btn-signup"
            disabled={btnState}
            onClick={(e) => history.push("/register")}
          >
            <FontAwesomeIcon icon={faUserPlus} /> Sign up New Account
          </button>
        </form>
        <br />
      </div>
    </React.Fragment>
  );
};

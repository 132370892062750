import * as idSeriesSpec from "../support/IdeationSeriesSpec";

const initialState = {
  list: [],
  current: idSeriesSpec.IdeationSeries(),
  currPage: 0,
  filter: "all",
  query: "all",
};

const getUpdatedList = (state, action) => {
  const idxIndex = state.list.findIndex(
    (ideation) => ideation.id === action.payload.id
  );

  console.log("index is " + idxIndex);

  if (idxIndex >= 0) {
    const listCopy = [...state.list];
    listCopy[idxIndex] = action.payload;
    return listCopy;
  } else {
    // Insert / concat the entry at the beginning of list
    const newList = [action.payload].concat(state.list);
    return newList;
  }
};

export const ideationSeriesReducer = (state = initialState, action) => {
  // IMPORTANT NOTE: It is required to create a new instance of the
  // state every time from current state and update the fields to
  // have redux-persist work properly and update the fields correct
  // This is mandatory in the case of multikey state ( like ideation )
  // as the object may remain same if we are ony updating 1 field

  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case idSeriesSpec.IDEATION_SERIES_NEW:
      newState.current = idSeriesSpec.IdeationSeries();
      return newState;

    case idSeriesSpec.IDEATION_SERIES_SAVED:
      newState.list = getUpdatedList(newState, action);
      newState.current = { ...action.payload };
      return newState;

    case idSeriesSpec.IDEATION_SERIES_EDIT:
      console.log("Ideation series edit: " + JSON.stringify(action));
      // We use the spread operator to avoid passing reference
      newState.current = { ...action.payload };
      return newState;

    case idSeriesSpec.IDEATION_SERIES_PUBLISHED:
      // We use the spread operator to avoid passing reference
      newState.current = { ...action.payload };
      newState.list = getUpdatedList(newState, action);
      return newState;

    case idSeriesSpec.IDEATION_SERIES_SUSPENDED:
      // We use the spread operator to avoid passing reference
      newState.current = { ...action.payload };
      newState.list = getUpdatedList(newState, action);
      return newState;

    case idSeriesSpec.IDEATION_SERIES_OWN_LIST_FETCHED:
      // We use the spread operator to avoid passing reference
      newState.list = newState.list.concat([...action.payload]);
      newState.currPage = parseInt(action.currPage);
      return newState;

    case idSeriesSpec.IDEATION_SERIES_OWN_SEARCH_TRIGGERED:
      newState.currPage = action.currPage;
      newState.list = [];
      newState.filter = new String(action.filter);
      newState.query = new String(action.query);
      return newState;

    default:
      return state;
  }
};

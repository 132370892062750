import * as masterDataSpec from "../support/MasterDataSpec";
import * as idMasterDataActions from "../actions/IdeationMasterDataActions";

const initialState = masterDataSpec.StandardIdeationMasterData();

export const ideationMasterDataReducer = (state = initialState, action) => {
  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case idMasterDataActions.IDEATION_MASTER_DATA_FETCH:
      if (!newState.categories)
        return masterDataSpec.StandardIdeationMasterData();

      // Check the expiry
      // Create the expiry time object
      var expiryTime = new Date(newState.expiry);

      console.log("Master data Expiry time is " + expiryTime);

      // If not expired, then return the current state,
      // If expired, return empty list
      if (expiryTime.getTime() > new Date().getTime()) {
        return newState;
      } else {
        newState.expired = true;
        return newState;
      }

    case idMasterDataActions.IDEATION_MASTER_DATA_UPDATE:
      // console.log("master data state : " + JSON.stringify(newState));
      newState.categories = action.payload.categories;
      newState.subjects = action.payload.subjects;
      newState.expired = false;
      newState.expiry = masterDataSpec.StandardIdeationMasterData().expiry;
      return newState;

    case idMasterDataActions.IDEATION_MASTER_ADD_CATEGORY:
      newState.categories.push({
        categoryRef: action.payload.catId,
        category: action.payload.catName,
        parentCategoryRef: action.payload.parentCategory,
      });
      return newState;

    case idMasterDataActions.IDEATION_MASTER_ADD_SUBJECT:
      newState.subjects.push(action.payload.subject);
      return newState;

    default:
      return state;
  }
};

import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as unsavedActions from "../../actions/UnsavedActions.js";

export const DiscardUnsavedButton = (props) => {
  const dispatch = useDispatch();

  const discardItem = () => {
    // dispatch the id
    dispatch(unsavedActions.discardUnsavedItem(props.unsavedId,props.type));
  };

  return (
    <Button variant="warning" onClick={() => discardItem()} block size="sm">
      Discard
    </Button>
  );
};

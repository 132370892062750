import {
  faEdit,
  faSave,
  faShareAltSquare,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router";
import * as unsavedSpec from "../support/UnsavedSpec";
import { DiscardUnsavedButton } from "./portal/DiscardUnsavedButton";

export const UnsavedList = (props) => {
  // Show the control
  const [show, setShow] = useState(true);

  // History object
  const history = useHistory();

  return (
    props.unsavedList &&
    props.unsavedList.length > 0 && (
      <div className="unsaved_container">
        {show ? (
          <>
            <Row>
              <Col md={10} style={{ paddingLeft: "25px" }}>
                <h4>{props.title}</h4>
                <p>
                  Please see the list of unsaved items. You can edit and save or
                  discard them
                </p>
              </Col>
              <Col md={2} align="right" style={{ paddingRight: "25px" }}>
                <a href="#" onClick={() => setShow(false)}>
                  Close
                </a>
              </Col>
            </Row>
            {props.unsavedList.map((item) => (
              <Row className="ideation-item align-items-center">
                <Col md={3}>
                  <p>
                    {item.heading}
                    <br />
                    <span className="ideation-subject-para">
                      on: <strong>{item.subHeading}</strong>
                    </span>
                  </p>
                </Col>
                <Col md={3}>
                  <p><b>Last saved:</b></p>
                  <i>
                    {unsavedSpec.formatLastSavedTimestamp(
                      item.unsavedTimestamp
                    )}
                  </i>
                </Col>
                <Col md={3}>
                  <Button
                    block
                    size="sm"
                    onClick={() =>
                      history.push(props.editBaseUrl + "/" + item.unsavedId)
                    }
                  >
                    Edit / View
                  </Button>
                </Col>
                <Col md={3}>
                  <DiscardUnsavedButton
                    unsavedId={item.unsavedId}
                    type={props.type}
                  />
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <div>
            There are unsaved items,{" "}
            <a href="#" onClick={() => setShow(true)}>
              click here
            </a>{" "}
            to see them
          </div>
        )}
      </div>
    )
  );
};

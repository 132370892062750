import { miAPI, URL_OWN_IDEATOR_METRICS } from "./RestAPISpec";

export const StandardIdeatorMetrics = () => {
  return {
    fetched: false,
    expiry: formatDateTime(new Date().addHours(10)),
    metrics: [],
  };
};

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

const formatDateTime = (dateTime) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(dateTime);
};

// export const readIdeatorMetrics = () => {

//     // Check if the data is present in the localStorage
//     let ideatorMetrics = localStorage.getItem("ideator-own-metrics");

//     // If present, return
//     if (ideatorMetrics) {

//         // Parse the object
//         const parsedMetrics = JSON.parse(ideatorMetrics);

//         // Create the expiry time object
//         var expiryTime = new Date(parsedMetrics.expiry);

//         // Check if expiry time is greater than parseMetrics
//         if (expiryTime.getTime() > new Date().getTime())
//             return parsedMetrics;

//     }

//     // If not found, then  need to read the data from server and read
//     miAPI.get(URL_OWN_IDEATOR_METRICS)
//         .then((result) => {
//             if (result.data.status == "success") {

//                 // Create the metrics
//                 const metrics = StandardIdeatorMetrics();

//                 // Set the data
//                 metrics.metrics = result.data.data;

//                 // Create json
//                 let ideatorMetricsJson = JSON.stringify(metrics);

//                 // Store json
//                 localStorage.setItem("ideator-own-metrics", ideatorMetricsJson);

//                 // Return metrics
//                 return metrics;
//             }
//             // return empty
//             return StandardIdeatorMetrics();
//         })
//         .catch((error) => {
//             // return empty
//             return StandardIdeatorMetrics();
//         })
// }

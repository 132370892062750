import * as ierSpec from "../support/IdeationEditRequestSpec";

export const updateAuthorOpenIdeationEdits = (
  editRequests,
  currPage,
  endOfList
) => {
  return {
    type: ierSpec.IER_ACTION_UPDATE_AUTHOR_OPEN_IDEATION_EDITS,
    payload: {
      editRequests: editRequests,
      currPage: currPage,
      endOfList: endOfList,
    },
  };
};

export const updateAuthorClosedIdeationEdits = (
  editRequests,
  currPage,
  endOfList
) => {
  return {
    type: ierSpec.IER_ACTION_UPDATE_AUTHOR_CLOSED_IDEATION_EDITS,
    payload: {
      editRequests: editRequests,
      currPage: currPage,
      endOfList: endOfList,
    },
  };
};

export const refreshAuthorOpenIdeationEdits = () => {
  return {
    type: ierSpec.IER_ACTION_REFRESH_AUTHOR_OPEN_IDEATION_EDITS,
  };
};

export const refreshAuthorClosedIdeationEdits = () => {
  return {
    type: ierSpec.IER_ACTION_REFRESH_AUTHOR_CLOSED_IDEATION_EDITS,
  };
};

export const setEditRequestViewType = (viewType, ideationId) => {
  return {
    payload: {
      viewType: viewType,
      ideationId: ideationId,
    },
    type: ierSpec.IER_ACTION_SET_EDIT_REQUEST_VIEW_TYPE,
  };
};

export const closeIdeationEditRequest = (editRequest) => {
  return {
    type: ierSpec.IER_ACTION_CLOSE_IDEATION_EDIT_REQUEST,
    payload: editRequest,
  };
};

import * as userSessionSpec from "../support/UserSessionSpec";

export const userSessionLoggedIn = (session) => {
  return {
    type: userSessionSpec.USER_SESSION_LOGIN,
    session: session,
  };
};

export const userSessionExpired = () => {
  return {
    type: userSessionSpec.USER_SESSION_EXPIRED,
    session: userSessionSpec.UserSession(),
  };
};

export const userSessionLogout = () => {
  return {
    type: userSessionSpec.USER_SESSION_LOGOUT,
    session: userSessionSpec.UserSession(),
  };
};

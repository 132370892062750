import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import styled from "styled-components";

// IMPORTANT NOTE:
// Put the styled components outside to avoid issues reported
// for having them in render method.
// Since its not exported from file, there won't be duplicate error
// even if we use the same style name in a different file
const AlertStyled = styled(Alert)`
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: pre-wrap;
`;

export class CustomAlert extends React.Component {
  state = {
    heading: "",
    message: "",
    show: false,
    variant: "",
  };

  showAlert = ({ heading, message, variant }) => {
    this.setState({
      show: true,
      heading: heading,
      message: message,
      variant: variant,
    });
  };

  clear = () => {
    this.setState({ show: false, heading: "", message: "", variant: "" });
  };

  toggleShow = () => {
    this.setState({ show: !this.state.show });
  };

  hide = () => {
    this.setState({ show: false });
  };

  render() {
    if (this.state.show) {
      return (
        <AlertStyled
          variant={this.state.variant}
          onClose={() => this.toggleShow()}
          dismissible
        >
          {this.state.heading && (
            <Alert.Heading>{this.state.heading}</Alert.Heading>
          )}
          <p>{this.state.message}</p>
        </AlertStyled>
      );
    }
    return "";
  }
}

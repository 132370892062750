import React, { useState, useRef, useEffect } from "react";
import "./css/Portal.css";
import { CustomAlert } from "../common/CustomAlert";
import { IdeationPreview } from "../common/IdeationPreview";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHTML from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import {
  miAPI,
  URL_IDEATION_SAVE,
  URL_IDEATION_GET_OWN,
  URL_IDEATION_PUBLISH,
  URL_IDEATION_SUSPEND,
  URL_MASTER_DATA_READ,
  URL_IDEATION_IMAGE_UPLOAD,
  URL_IDEATION_IMAGE_LOAD,
} from "../../support/RestAPISpec";
import { useSelector, useDispatch } from "react-redux";
import * as ideationSpec from "../../support/IdeationSpec";
import {
  ideationCreateNew,
  ideationSaved,
  ideationEdit,
  ideationPublished,
  ideationSuspended,
  ideationUpdateUnsaved,
} from "../../actions/IdeationAction";
import { Prompt, useParams } from "react-router-dom";
import * as masterDataSpec from "../../support/MasterDataSpec";
import * as unsavedSpec from "../../support/UnsavedSpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import * as idmActions from "../../actions/IdeationMasterDataActions";
import * as ierActions from "../../actions/IdeationEditRequestActions";
import SelectSearch from "react-select-search";
import "./css/react-select-search.css";
import { MasterDataForm } from "./MasterDataForm";
import Immutable from "immutable";
import { DefaultDraftBlockRenderMap } from "draft-js";
import * as idpActions from "../../actions/IdeatorProfileAction";
import * as idpSpec from "../../support/IdeatorProfileSpec";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { ShareDialog } from "../common/ShareDialog";
import * as sdActions from "../../actions/ShareDialogActions";
import { useInterval } from "../common/UseInterval";
import {
  IER_STATUS_OPEN,
  IER_VIEW_TYPE_ALL,
  IER_VIEW_TYPE_IDEATION,
} from "../../support/IdeationEditRequestSpec";
import { IdeationEditRequestListForStatus } from "../IdeationEditRequestListForStatus";

export const IdeationForm = (props) => {
  // State fields
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [ideationData, setIdeationData] = useState(ideationSpec.Ideation());
  const [masterCategories, setMasterCategories] = useState(
    masterDataSpec.StandardIdeationMasterData().categories
  );
  const [saveBtnState, setSaveBtnState] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [shareIdeation, setShareIdeation] = useState({});

  const blockRenderMap = Immutable.Map({
    section: {
      element: "section",
    },
  });

  // Include 'paragraph' as a valid block and updated the unstyled element but
  // keep support for other draft default block types
  const extendedBlockRenderMap =
    DefaultDraftBlockRenderMap.merge(blockRenderMap);

  // Reference to the ideatioView for setting the content
  const ideationViewRef = useRef();

  // Reference to the custom alert
  const alertRef = useRef();

  // Reference to the redux dispatcher
  const dispatch = useDispatch();

  // Get the id from the route params
  const { id } = useParams();

  // Get the path
  const { path } = props.match;

  // Get the userSession
  const userSession = useSelector((state) => state.userSession);

  // The current Ideation
  const currIdeation = useSelector((state) => state.ideation.current);

  // Get the ideationList
  const ideationList = useSelector((state) => state.ideation.list);

  // Get the masterData
  const masterData = useSelector((state) => state.masterData);

  // Get the unsaved
  const unsaved = useSelector((state) => state.unsaved);

  // Get the editRequests
  const editRequests = useSelector((state) => state.ideationEditRequests);

  // Get the pubIdeations
  const pubIdeations = useSelector(
    (state) => state.ideatorProfile.pubIdeations
  );

  // Set the meax length
  const maxLength = 500;

  useInterval(() => {
    postCurrIdeationToUnsaved();
  }, 30000);

  // On load, set the userSession.username as author
  useEffect(() => {
    dispatch(idmActions.ideationMasterDataFetch());
  }, []);

  // Watch the masterData
  useEffect(() => {
    // Check if masterDAta is defined
    if (
      !masterData ||
      masterData.expired === undefined ||
      masterData.expired === true
    )
      fetchMasterData();
    else setMasterCategories(masterData.categories);
  }, [masterData]);

  // Watch the path changes and trigger the view based on the id
  // retrieved from the params.
  // IMPORTANT NOTE: This can be also done by watching the id directly.
  // But path provides more details on the path changes when there are
  // more actions that we need to handle
  useEffect(() => {
    // Check if the id is present
    if (id) {
      // Check if the path contains unsaved
      if (path.includes("unsaved")) {
        fetchUnsavedIdeation(id);
      } else {
        fetchIdeationForEdit(id);
      }
    } else {
      alertRef.current.clear();
      dispatch(ideationCreateNew());

      // If we are creating new ideation, set the view type to all for the
      // listing and ideation as empty so that the component will be hidden
      dispatch(ierActions.setEditRequestViewType(IER_VIEW_TYPE_ALL, ""));
    }
  }, [path]);

  // Watch the userSession and set the data
  useEffect(() => {
    // Set the ideationViewRef author
    ideationViewRef.current.setAuthor(userSession.username);
  }, [userSession]);

  useEffect(() => {
    handleParentCategoryChange(parentCategory);
  }, [parentCategory]);

  // We can use useEffect with the value referenced as the useSelector
  // reference to identifying the effect of change to the values
  useEffect(() => {
    console.log("useEffect -> currIdeation: " + JSON.stringify(currIdeation));
    const parentCat = masterDataSpec.getParentCategoryRefForCategory(
      masterData.categories,
      currIdeation.categoryRef
    );
    setParentCategory(parentCat);
    setCategories(
      masterDataSpec.getCategoryOptions(masterData.categories, parentCat)
    );
    htmlToEditorState(currIdeation.content);
    refreshIdeationViewData(currIdeation);
    setIdeationData(currIdeation);
  }, [currIdeation]);

  const fetchUnsavedIdeation = (id) => {
    // Find the entry
    const unsavedEntry = unsavedSpec.getUnsavedEntry(unsaved.ideations, id);

    // Check if exists
    if (unsavedEntry) {
      dispatch(ideationEdit(unsavedEntry));
    }
  };

  const postCurrIdeationToUnsaved = () => {
    console.log("data change isDircty on post: ");
    // The ideation data to save
    const idData = {
      ...ideationData,
      unsavedTimestamp: new Date(),
    };
    // Check if the currIdeation has uuid
    if (!ideationData.unsavedId) {
      idData["unsavedId"] =
        ideationData.id &&
        ideationData.id != null &&
        ideationData.id !== undefined
          ? ideationData.id
          : Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    // Set the ideation to the one with the saved id
    setIdeationData(idData);

    // Console log
    console.log("Saving ideation locally: " + JSON.stringify(ideationData));

    // Post the event
    dispatch(ideationUpdateUnsaved(idData));
  };

  // Refresh the view data based on the data processed
  const refreshIdeationViewData = (idObj) => {
    ideationViewRef.current.setContent(idObj.content);
    ideationViewRef.current.setTopic(idObj.topic);
    ideationViewRef.current.setSubject(idObj.subject);
    ideationViewRef.current.setCategory(
      masterDataSpec.getCategoryForRef(masterCategories, idObj.categoryRef)
    );
    ideationViewRef.current.setAuthor(userSession.username);
  };

  // Read the categories
  const fetchMasterData = () => {
    console.log("fetching master data");
    // If not found, then  need to read the data from server and read
    miAPI.get(URL_MASTER_DATA_READ).then((result) => {
      if (result.data.status == "success") {
        dispatch(idmActions.ideationMasterDataUpdate(result.data.data));
      }
    });
  };

  const triggerOnEditEvents = (ideation) => {
    dispatch(ideationEdit(ideation));
    dispatch(
      ierActions.setEditRequestViewType(IER_VIEW_TYPE_IDEATION, ideation.id)
    );
  };

  // Read the user's own ideation for editing
  // On success, dispatch the edit option
  const fetchIdeationForEdit = (ideationRef) => {
    // Check if the list contains the id
    if (ideationList && ideationList.length > 0) {
      let ideation = ideationList.find((idtn) => idtn.id === ideationRef);
      if (ideation) {
        triggerOnEditEvents(ideation);
        return;
      }
    }

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Loading Ideation"));

    // Read the details
    miAPI
      .get(URL_IDEATION_GET_OWN + ideationRef)
      .then((result) => {
        console.log("Result : " + JSON.stringify(result));
        if (result.data.status == "success") {
          const ideationObj = result.data.data;
          // ideationObj.editorState = htmlToEditorState(result.data.data.content);
          console.log("Edit ideation : " + JSON.stringify(ideationObj));
          triggerOnEditEvents({ ...ideationObj });
        } else {
          alertRef.current.showAlert({
            heading: "Ideation Fetch",
            message: "Error retrieving ideation : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        console.log("error : " + JSON.stringify(error));
        alertRef.current.showAlert({
          heading: "Ideation Fetch",
          message: "Error fetching ideation",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Suspend the ideation
  const suspendIdeation = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress());

    miAPI
      .post(URL_IDEATION_SUSPEND, "ideationRef=" + ideationData.id)
      .then((result) => {
        console.log("Result " + JSON.stringify(result));
        // Check the result
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Ideation Suspend",
            message: "Ideation is suspended now.",
            variant: "success",
          });
          // ideationData.id = result.data.data;
          ideationData.status = "SUSPENDED";
          console.log("Ideation on suspend: " + JSON.stringify(ideationData));
          dispatch(ideationSuspended(ideationData));
          dispatch(
            idpActions.removeSuspendedIdeation({
              id: ideationData.id,
              topic: ideationData.topic,
            })
          );
        } else {
          alertRef.current.showAlert({
            heading: "Ideation Suspend",
            message: "Error suspending ideation : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Ideation Suspend",
          message: "Error suspending ideation",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Publish the ideation
  const publishIdeation = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress());

    miAPI
      .post(URL_IDEATION_PUBLISH, "ideationRef=" + ideationData.id)
      .then((result) => {
        console.log("Result " + JSON.stringify(result));
        // Check the result
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Ideation Publish",
            message: "Congrats!!, You have published the ideation",
            variant: "success",
          });
          // ideationData.id = result.data.data;
          ideationData.status = "PUBLISHED";
          console.log("Ideation on publish: " + JSON.stringify(ideationData));
          dispatch(ideationPublished(ideationData));
          dispatch(
            idpActions.addPublishedIdeation({
              id: ideationData.id,
              topic: ideationData.topic,
              categoryRef: ideationData.categoryRef,
              subject: ideationData.subject,
            })
          );
        } else {
          alertRef.current.showAlert({
            heading: "Ideation Publish",
            message: "Error publishing ideation : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Ideation Publish",
          message: "Error publishing ideation",
          variant: "warning",
        });
        console.log("error: " + JSON.stringify(error));
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Method to handle the saving of the ideation
  const saveIdeation = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress());

    miAPI
      .post(URL_IDEATION_SAVE, ideationData)
      .then((result) => {
        console.log("Result " + JSON.stringify(result));
        // Check the result
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Ideation Save",
            message: "Ideation saved successfully!!",
            variant: "success",
          });
          console.log("saveIdeation" + JSON.stringify(result.data.data));
          ideationData.id = result.data.data.id;
          ideationData.slug = result.data.data.slug;
          console.log("saveIdeation" + JSON.stringify(ideationData));
          dispatch(ideationSaved(ideationData));
        } else {
          alertRef.current.showAlert({
            heading: "Ideation Save",
            message: "Error saving ideation : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Ideation Save",
          message: "Error saving ideation",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Method to set the editor content ( like editing)
  const htmlToEditorState = (html) => {
    const contentBlock = htmlToDraft(html);
    const newContentState = ContentState.createFromBlockArray(contentBlock);
    const newEditorState = EditorState.createWithContent(newContentState);
    handleEditorStateChange(newEditorState);
  };

  // Upload callback for the image upload method from editor
  const uploadCallback = (file, callback) => {
    console.log("File is : " + file.size);
    console.log("Callback :" + callback);
    return new Promise((resolve, reject) => {
      var reader = new window.FileReader();
      reader.onloadend = () => {
        console.log("Loading completed");
        miAPI
          .post(URL_IDEATION_IMAGE_UPLOAD, {
            name: file.name,
            data: reader.result,
          })
          .then((result) => {
            console.log("Uploaded Data", JSON.stringify(result));
            if (result.data.status !== "success") {
              alertRef.current.showAlert({
                heading: "Image error",
                message: "Unable to upload image : \n" + result.data.errordesc,
                variant: "warning",
              });
              resolve({ data: {} });
              return;
            }
            const imageUrl = URL_IDEATION_IMAGE_LOAD + result.data.data;
            resolve({ data: { link: imageUrl } });
          });
      };
      reader.readAsDataURL(file);
    });
  };

  // Method to set the ideation field value and update the
  // ideationData state
  const setIdeationFieldValue = (field, value) => {
    const idData = { ...ideationData, [field]: value };
    setIdeationData(idData);
    console.log("Ideation data on field value: " + JSON.stringify(idData));
  };

  // Method to handle the topic change
  const handleTopicChange = (event) => {
    ideationViewRef.current.setTopic(event.target.value);
    setIdeationFieldValue(event.target.name, event.target.value);
  };

  // Handle the category change
  const handleCategoryChange = (categoryRef) => {
    ideationViewRef.current.setCategory(
      masterDataSpec.getCategoryForRef(masterCategories, categoryRef)
    );
    setIdeationFieldValue("categoryRef", categoryRef);
  };

  // Handle the category change
  const handleParentCategoryChange = (parentCategoryRef) => {
    console.log("category change called");
    setCategories(
      masterDataSpec.getCategoryOptions(masterCategories, parentCategoryRef)
    );
  };

  // Handle the subject change
  const handleSubjectchange = (subject) => {
    ideationViewRef.current.setSubject(subject);
    setIdeationFieldValue("subject", subject);
  };

  // Update the editor state change
  // Creates the hml and then set the content in ideationData
  // NOTE : Do not call setIdeationFieldValue as this is called from
  // useEffect for edit cases and can reset other fields
  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let content = draftToHTML(convertToRaw(editorState.getCurrentContent()));
    console.log("content : " + content);
    ideationViewRef.current.setContent(content);
    ideationData.content = content;
  };

  // Method to handle editor state change
  // This is required to see the typed content in the file
  const onEditorStateChange = (editorState) => {
    handleEditorStateChange(editorState);
  };

  return (
    <div className="section_container content_section">
      <ShareDialog item={shareIdeation} type={sdActions.SD_TYPE_IDEATION} />
      <Prompt
        message={(location, action) => {
          // Get the data
          let unsavedObj = unsavedSpec.getUnsavedEntry(
            unsaved.ideations,
            ideationData.unsavedId
          );

          // Check if there is an unsaved entry and its not saved
          if (unsavedObj && unsavedObj.unSavedStatus !== "saved") {
            // Post to unsaved
            postCurrIdeationToUnsaved();

            // Prompt for the user
            return "You have unsaved changes. Are you sure to navigate ? ";
          }

          return true;
        }}
      />
      <div className="content_header">
        <Row>
          <Col md={12}>
            <div className="content_header_heading">
              <Row>
                <Col md={12}>
                  {!ideationData.id && <h5>New Ideation</h5>}
                  {ideationData.id && (
                    <h5>Update Ideation ({ideationData.status})</h5>
                  )}
                </Col>
              </Row>
            </div>
            <div className="content_header_controls">
              <Row>
                <Col md={{ span: 4 }}>
                  {ideationData.status === "PUBLISHED" && (
                    <FontAwesomeIcon
                      className="ideation_form_icon"
                      icon={faShareAlt}
                      onClick={() => {
                        console.log(
                          "shareDialog -> Set data from caller " +
                            JSON.stringify(ideationData)
                        );
                        setShareIdeation(ideationData);
                        dispatch(sdActions.showShareDialog());
                      }}
                    />
                  )}
                </Col>
                <Col md={{ span: 4 }}>
                  {(ideationData.status === "NEW" ||
                    ideationData.status === "SUSPENDED") && (
                    <Button
                      variant="primary"
                      disabled={
                        !(
                          ideationData.status === "NEW" ||
                          ideationData.status === "SUSPENDED"
                        ) || !ideationData.id
                      }
                      onClick={() => publishIdeation()}
                      block
                    >
                      Publish
                    </Button>
                  )}
                  {ideationData.status === "PUBLISHED" && (
                    <Button
                      variant="warning"
                      disabled={!(ideationData.status === "PUBLISHED")}
                      onClick={() => suspendIdeation()}
                      block
                    >
                      Suspend
                    </Button>
                  )}
                </Col>
                <Col md={4}>
                  <Button
                    disabled={saveBtnState}
                    variant="success"
                    onClick={() => saveIdeation()}
                    block
                  >
                    Save Ideation
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content_section_form">
        <Container>
          <CustomAlert ref={alertRef} />
        </Container>
        <Row>
          <Col
            lg={{ span: 6 }}
            md={{ span: 6 }}
            xs={12}
            className="content_form_container"
          >
            <Container>
              <Row>
                <Col
                  md={{ span: 12 }}
                  lg={{ span: 10, offset: 1 }}
                  sm={{ span: 12 }}
                  xl={{ span: 8, offset: 2 }}
                >
                  <Form>
                    <p>Provide the details for the Ideation </p>
                    <Form.Control
                      type="text"
                      name="topic"
                      placeholder="Ideation Topic"
                      required
                      onChange={(e) => handleTopicChange(e)}
                      value={ideationData.topic}
                    />
                    <IdeationEditRequestListForStatus
                      status={IER_STATUS_OPEN}
                      alertRef={alertRef}
                      ideationId={editRequests.author.ideationId}
                      editRequestsForStatus={editRequests.author.open}
                      viewType={editRequests.author.viewType}
                      compactMode={true}
                    />
                    {/* <textarea className="form-control" rows="15" placeholder="Content" /> */}
                    <Editor
                      blockRenderMap={extendedBlockRenderMap}
                      // Set the editor state
                      editorState={editorState}
                      // Set the listener for the editor state change
                      // This is required for the typing to happen
                      onEditorStateChange={onEditorStateChange}
                      // Allow pasting of text
                      handlePastedText={() => false}
                      // Handle the maximum character length
                      handleBeforeInput={(val) => {
                        const textLength = editorState
                          .getCurrentContent()
                          .getPlainText().length;
                        if (val && textLength >= maxLength) {
                          return "handled";
                        }
                        return "not-handled";
                      }}
                      // Handle the maximum lenght in the pasted text
                      handlePastedText={(val) => {
                        const textLength = editorState
                          .getCurrentContent()
                          .getPlainText().length;
                        return val.length + textLength >= maxLength;
                      }}
                      // Option to mention the ideators published ideations as mentions
                      mention={{
                        separator: " ",
                        trigger: "@",
                        suggestions:
                          idpSpec.getIdeationEditorMentionSuggestions(
                            pubIdeations
                          ),
                      }}
                      // Create the toolbar options to be enabled for the editor
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "image",
                          "colorPicker",
                          "list",
                          "textAlign",
                        ],
                        colorPicker: {
                          colors: [
                            "rgb(97,189,109)",
                            "rgb(26,188,156)",
                            "rgb(84,172,210)",
                            "rgb(44,130,201)",
                            "rgb(147,101,184)",
                            "rgb(71,85,119)",
                            "rgb(204,204,204)",
                            "rgb(65,168,95)",
                            "rgb(0,168,133)",
                            "rgb(61,142,185)",
                            "rgb(41,105,176)",
                            "rgb(85,57,130)",
                            "rgb(40,50,78)",
                            "rgb(0,0,0)",
                            "rgb(247,218,100)",
                            "rgb(251,160,38)",
                            "rgb(235,107,86)",
                            "rgb(226,80,65)",
                            "rgb(163,143,132)",
                            "rgb(239,239,239)",
                            "rgb(255,255,255)",
                            "rgb(250,197,28)",
                            "rgb(243,121,52)",
                            "rgb(209,72,65)",
                            "rgb(184,49,47)",
                            "rgb(124,112,107)",
                            "rgb(209,213,216)",
                          ],
                        },
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        blockType: {
                          options: [
                            "Normal",
                            "H1",
                            "H2",
                            "H3",
                            "H4",
                            "H5",
                            "H6",
                            "Code",
                          ],
                        },
                        image: {
                          urlEnabled: false,
                          uploadEnabled: true,
                          uploadCallback: uploadCallback,
                          previewImage: true,
                          inputAccept: "image/jpeg,image/jpg,image/png",
                        },
                      }}
                    />
                    <p className="ideation-warning-para">
                      <strong>
                        Characters remaining:{" "}
                        {maxLength -
                          editorState.getCurrentContent().getPlainText().length}
                      </strong>
                      <br />
                      <i>
                        NOTE: Any scripting or links will be removed from
                        content
                      </i>
                    </p>
                    {ideationData.unsavedTimestamp && (
                      <p className="ideation-warning-para">
                        Last auto-saved:{" "}
                        {unsavedSpec.formatLastSavedTimestamp(
                          ideationData.unsavedTimestamp
                        )}
                      </p>
                    )}
                    <SelectSearch
                      options={masterDataSpec.getParentCategoryOptions(
                        masterData.categories
                      )}
                      value={parentCategory}
                      name="parentCategory"
                      placeholder="Top Category"
                      search={true}
                      onChange={(e) => handleParentCategoryChange(e)}
                    />
                    <SelectSearch
                      options={categories}
                      value={ideationData.categoryRef}
                      name="categoryRef"
                      emptyMessage="Please choose top category"
                      placeholder="Category"
                      search={true}
                      onChange={(e) => handleCategoryChange(e)}
                    />
                    <MasterDataForm type="category" />
                    <SelectSearch
                      options={masterDataSpec.getSubjectOptions(
                        masterData.subjects
                      )}
                      value={ideationData.subject}
                      name="subject"
                      placeholder="Subject"
                      search={true}
                      onChange={(e) => handleSubjectchange(e)}
                    />
                    <MasterDataForm type="subject" />
                  </Form>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={{ span: 6 }} md={{ span: 6 }} xs={12}>
            <Container>
              <Row>
                <Col
                  md={{ span: 12 }}
                  lg={{ span: 10, offset: 1 }}
                  sm={{ span: 12 }}
                  xl={{ span: 8, offset: 2 }}
                >
                  <IdeationPreview ref={ideationViewRef} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};

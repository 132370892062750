import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as blActions from "../../actions/BlockingLoaderAction";
import {
  URL_PUBLIC_VIEWER_BASE,
  URL_PUBLIC_VIEWER_IDEATION,
  URL_PUBLIC_VIEWER_IDEATION_SERIES_SLUG,
  URL_PUBLIC_VIEWER_IDEATION_SLUG,
} from "../../support/RestAPISpec";
import { IdeationView } from "../common/IdeationView";
import gstoreImg from "../outer/img/google-play-badge.svg";
import astoreImg from "../outer/img/app-store-badge.svg";
import "./css/viewer-styles.css";
import * as idsSpec from "../../support/IdeationSeriesSpec";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
import { Badge } from "react-bootstrap";

export const IdeationSeriesViewer = (props) => {
  // Refer to the history
  const history = useHistory();

  // Reference to the redux dispatcher
  const dispatch = useDispatch();

  // Get the id from the route params
  const { slug } = useParams();

  // Ideation series
  const [ideationSeries, setIdeationSeries] = useState(
    idsSpec.IdeationSeries()
  );

  // Set the ideations in the series
  const [ideations, setIdeations] = useState([]);

  // Show the index
  const [showIndex, setShowIndex] = useState(false);

  // Error state
  const [ideationError, setIdeationError] = useState(false);

  // The download alert
  const [showDlgAlert, setShowDlgAlert] = useState(true);

  useEffect(() => {
    fetchPublicSeriesIdeation();
  }, []);

  const fetchPublicSeriesIdeation = () => {
    console.log("Slug is : " + slug);

    // By default set to find by slug
    let url = URL_PUBLIC_VIEWER_IDEATION_SERIES_SLUG + "/" + slug;

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Loading Ideation..."));

    // Use Axios directly to avoid using the interceptors when
    // session is not there.
    Axios.get(url, {
      withCredentials: false,
    })
      .then((result) => {
        if (result.data.status === "success") {
          // Set the ideation
          setIdeationSeries({
            ...result.data.data.series,
            categoryName: result.data.data.categoryName,
          });
          setIdeations(result.data.data.ideationContent);
        } else {
          setIdeationError(true);
        }
      })
      .catch(() => setIdeationError(true))
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }} sm={{ span: 12 }} className="mt-3">
        {!ideationError && (
          <>
            <div className="viewer-style-container">
              <div className="ideation-series-info-section">
                <h5>{ideationSeries.name}</h5>
                <Badge
                  pill
                  variant="warning"
                  data-toggle="tooltip"
                  title={ideationSeries.categoryName}
                >
                  {ideationSeries.categoryName}
                </Badge>
                <hr />
                <p>{ideationSeries.desc}</p>
                <Row>
                  <Col md={8} sm={8} xs={8} className="align-items-center">
                    <p>
                      <b>Content Index</b>
                    </p>
                  </Col>
                  <Col md={4} sm={4} xs={4} align="right">
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => setShowIndex(!showIndex)}
                    >
                      {showIndex ? "Hide" : "Show"}
                    </Button>
                  </Col>
                </Row>
                {showIndex &&
                  ideations.map((e, index) => (
                    <div className="ideation-series-info-item">
                      <Row>
                        <Col md={1} sm={1} xs={1}>
                          {index + 1}
                          {"."}
                        </Col>
                        <Col md={9} sm={9} xs={9}>
                          <a href={"#" + e.id}>{e.topic}</a>
                        </Col>
                        <Col md={1} sm={1} xs={1} align="center">
                          <a
                            href={URL_PUBLIC_VIEWER_BASE + "/" + e.slug}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faBookmark} color="red" />
                          </a>
                        </Col>
                      </Row>
                    </div>
                  ))}
                {showIndex && (
                  <Row>
                    <Col align="center">
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => setShowIndex(false)}
                      >
                        Hide
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <div className="viewer-style-container">
              <Alert
                variant="info"
                dismissible
                show={showDlgAlert}
                onClose={() => setShowDlgAlert(false)}
              >
                <Alert.Heading>
                  Download free app
                  <hr />{" "}
                </Alert.Heading>
                <h6 style={{ marginTop: "0px" }}>
                  Download the free mobile app for this and more content on{" "}
                  <b>"{ideationSeries.categoryName}"</b>.
                </h6>
                <h5>Features</h5>
                <p>
                  <ul>
                    <li>
                      Community ranked micro content ( 30 second read) on
                      preferred topics
                    </li>
                    <li>Navigate using simple swipe gestures</li>
                    <li>Vote and favorite for great content</li>
                    <li>
                      Notification and reminders on new content that you love
                    </li>
                  </ul>
                </p>
                <hr />
                <p align="center">
                  <a
                    className="badge-link"
                    href="https://play.google.com/store/apps/details?id=com.microideation.app"
                    target="_blank"
                  >
                    <img src={gstoreImg} width="150" alt="" />
                  </a>
                  <a
                    className="badge-link"
                    href="https://apps.apple.com/us/app/microideation/id1582752866"
                    target="_blank"
                  >
                    <img src={astoreImg} width="150" alt="" />
                  </a>
                </p>
              </Alert>
            </div>
          </>
        )}
        <div className="viewer-style-container">
          {ideationError && (
            <Alert variant="danger" align="center">
              <Alert.Heading>
                Link Error
                <hr />{" "}
              </Alert.Heading>
              <h5 align="center" style={{ marginTop: "0px" }}>
                <strong>
                  <i>
                    Sorry, unable to retrieve the series. Ensure that its a
                    valid link!!
                  </i>
                </strong>
              </h5>
              <hr />
              <Button
                variant="primary"
                onClick={() => {
                  history.push("/");
                }}
              >
                Home
              </Button>
            </Alert>
          )}
        </div>
        {ideations
          .map((e) => ({
            ...e,
            categoryName: "test",
          }))
          .map((ideation) => (
            <div className="viewer-style-container" id={ideation.id}>
              <IdeationView ideation={ideation} />
            </div>
          ))}
        <div className="viewer-style-container">
          <Row>
            <Col md={12} align="center">
              <h5 className="mt-3 fg-color-white">
                Download the app free for more content with recommendations and
                preferences.
              </h5>
              <a
                className="badge-link"
                href="https://play.google.com/store/apps/details?id=com.microideation.app"
                target="_blank"
              >
                <img src={gstoreImg} width="240" alt="" />
              </a>
              <a
                className="badge-link"
                href="https://apps.apple.com/us/app/microideation/id1582752866"
                target="_blank"
              >
                <img src={astoreImg} width="240" alt="" />
              </a>
            </Col>
          </Row>
        </div>
        <div className="viewer-style-container mt-3">
          <Row>
            <Col md={12} align="center">
              <h3 className="fg-color-white">
                Are you a subject matter expert ?
              </h3>
              <p className="fg-color-white">
                Craft beautiful single page ideations on topics under your area
                of expertise and get recognized.
              </p>
              <p className="center">
                <Button
                  className="mb-2 mr-2"
                  variant="primary outline"
                  onClick={() => history.push("/register")}
                >
                  Sign Up Now
                </Button>
                <Button
                  className="mb-2"
                  variant="outline-light"
                  onClick={() => history.push("/about")}
                >
                  Learn more..
                </Button>
              </p>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

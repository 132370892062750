import * as idpSpec from "../support/IdeatorProfileSpec";
import * as idpActions from "../actions/IdeatorProfileAction";

export const ideatorProfileReducer = (
  state = idpSpec.IdeatorProfile(),
  action
) => {
  // Create a copy of state
  const newState = Object.assign({}, state);

  switch (action.type) {
    case idpActions.IDP_ACTION_REFRESH_PUBLISHED_IDEATIONS:      
      newState.pubIdeations = action.payload;
      return newState;
    case idpActions.IDP_ACTION_ADD_PUBLISHED_IDEATION:
      // Add to the list if not already present
      newState.pubIdeations.pushIfNotExist(action.payload, function (e) {    
        return action.payload.id === e.id;
      });
      return newState;
    case idpActions.IDP_ACTION_REMOVE_SUSPENDED_IDEATION:
      // Add to the list if not already present    
      newState.pubIdeations.removeIfExist(function (e) {        
        return action.payload.id === e.id;
      });
      return newState;
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import "./css/Portal.css";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Carousel,
} from "react-bootstrap";
import { IdeationIntro } from "./IdeationIntro";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RandomIdeationView } from "../common/RandomIdeationView";

const InlineCarousel = styled(Carousel)`
  display: inline-block;
  overflow: hidden;
`;
export const GettingStartedForm = () => {
  // Create thehistory
  const history = useHistory();

  /* 
      Below code is required for setting the image url dyamically
      for  class
      Set the imgStyle on the style attribute which will 
      parse it as css
    */

  // Set the image URL
  const [imgUrl, setImgUrl] = useState("");

  // On load set the url
  useEffect(() => {
    setImgUrl("");
  }, []);

  // imgStyle
  var imgStyle = {
    background: "url(" + imgUrl + ")",
  };

  return (
    <div class="section_container content_section">
      <div class="content_header">
        <Row>
          <Col md={12}>
            <div class="content_header_heading">
              <h5>Let's get started...</h5>
            </div>
            <div class="content_header_controls">
              {/* <Button variant="success">Save Ideation</Button> */}
            </div>
          </Col>
        </Row>
      </div>
      <div class="content_section_form">
        <Row>
          <Col
            lg={{ span: 6 }}
            md={{ span: 6 }}
            xs={12}
            className="content_form_container"
          >
            <IdeationIntro />
          </Col>
          <Col lg={{ span: 6 }} md={{ span: 6 }} xs={12}>
            <Container>
              <Row>
                <Col
                  md={{ span: 12 }}
                  lg={{ span: 10, offset: 1 }}
                  sm={{ span: 12 }}
                  xl={{ span: 8, offset: 2 }}
                >
                  <h5> Craft & share microideations </h5>
                  {/* set dynamic background<div style ={imgStyle} class="ideation_preview_viewport"></div> */}
                  <RandomIdeationView indicatorTheme="dark"/>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import "./css/Portal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faStickyNote,
  faListUl,
  faHeart,
  faThumbsUp,
  faBullhorn,
  faBullseye,
  faComment,
  faCommentAlt,
  faLink,
  faWindowRestore,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { IdeationForm } from "./IdeationForm";
import { OwnIdeationListForm } from "./OwnIdeationListForm";
import { GettingStartedForm } from "./GettingStartedForm";
import { Route, Switch, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as ideatorActions from "../../actions/IdeatorMetricActions";
import {
  miAPI,
  URL_OWN_IDEATOR_METRICS,
  URL_IDEATOR_IDEATION_MINIMAL,
  URL_MASTER_DATA_READ,
  URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS,
} from "../../support/RestAPISpec";
import { ResendActivationLinkSection } from "./ResendActivationLinkSection";
import * as idpActions from "../../actions/IdeatorProfileAction";
import { IdeationSeriesForm } from "./IdeationSeriesForm";
import { OwnIdeationSeriesListForm } from "./OwnIdeationSeriesListForm";
import * as idmActions from "../../actions/IdeationMasterDataActions";
import * as ierActions from "../../actions/IdeationEditRequestActions";
import { IdeationEditRequestListForm } from "./IdeationEditRequestListForm";

export const PortalHome = (props) => {
  // ideator own metrics
  const [ideatorOwnMetrics, setIdeatorOwnMetrics] = useState([]);
  const [ideationFormActive, setIdeationFormActive] = useState(false);
  const [listOwnIdeationsActive, setListOwnIdeationsActive] = useState(false);
  const [listOwnIdeationSeriesActive, setListOwnIdeationSeriesActive] =
    useState(false);

  const [feedbackDlgShow, setFeedbackDlgShow] = useState(false);

  // Create the history
  const history = useHistory();

  // Define dispatch
  const dispatch = useDispatch();

  // Get the location
  const location = useLocation();

  // Get the metrics
  const ideatorMetrics = useSelector((state) => state.ideatorMetrics);

  // Get the userSession
  const userSession = useSelector((state) => state.userSession);

  // Get the masterData
  const masterData = useSelector((state) => state.masterData);

  // Get the ideation edit requetss
  const ideationEditRequests = useSelector(
    (state) => state.ideationEditRequests
  );

  // On load, set the userSession.username as author
  useEffect(() => {
    // Read the ideator metrics
    dispatch(ideatorActions.ideatorMetricDataFetch());
    // refresh the ideator ideations ( used for @mentions )
    refreshIdeatorIdeationForProfile();
    // Read the master data
    dispatch(idmActions.ideationMasterDataFetch());
    // Fetch the ideator edit requests
    // fetchIdeatorOpenEditRequests();
  }, []);

  // Watch the masterData
  useEffect(() => {
    // Check if masterDAta is defined
    if (
      !masterData ||
      masterData.expired === undefined ||
      masterData.expired === true
    )
      fetchMasterData();
  }, [masterData]);

  // Watch the location
  useEffect(() => {
    // If the path contains ideation-form, set the form as active
    if (location.pathname.includes("ideation-form"))
      setIdeationFormActive(true);
    else setIdeationFormActive(false);

    // If the path contains my-ideations, then set the icon active
    if (location.pathname.includes("my-ideations"))
      setListOwnIdeationsActive(true);
    else setListOwnIdeationsActive(false);

    // If the path contains my-ideation-series, then set the icon active
    if (location.pathname.includes("ideation-series"))
      setListOwnIdeationSeriesActive(true);
    else setListOwnIdeationSeriesActive(false);
  }, [location]);

  // Watch the ideatorMetrics
  useEffect(() => {
    // Check if data is present
    // The fetched status checking is important to avoid recursive calling
    if (
      !ideatorMetrics ||
      (ideatorMetrics.metrics.length == 0 && ideatorMetrics.fetched == false)
    )
      fetchIdeatorMetrics();
    else setIdeatorOwnMetrics(ideatorMetrics.metrics);
  }, [ideatorMetrics]);

  // Read the categories
  const fetchMasterData = () => {
    // If not found, then  need to read the data from server and read
    miAPI.get(URL_MASTER_DATA_READ).then((result) => {
      if (result.data.status == "success") {
        dispatch(idmActions.ideationMasterDataUpdate(result.data.data));
      }
    });
  };

  const refreshIdeatorIdeationForProfile = () => {
    // If not found, then  need to read the data from server and read
    miAPI.get(URL_IDEATOR_IDEATION_MINIMAL).then((result) => {
      if (result.data.status === "success") {
        dispatch(idpActions.refreshPublishedIdeations(result.data.data));
      }
    });
  };

  // Fetch the ideator metrics
  const fetchIdeatorMetrics = () => {
    // If not found, then  need to read the data from server and read
    miAPI.get(URL_OWN_IDEATOR_METRICS).then((result) => {
      if (result.data.status == "success") {
        dispatch(
          ideatorActions.ideatorMetricDataUpdate(result.data.data.metrics)
        );
      }
    });
  };

  // Fetch the ideator edit requests
  const fetchIdeatorOpenEditRequests = () => {
    // read the data
    miAPI
      .get(
        URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS +
          "/OPEN?page=" +
          ideationEditRequests.author.open.currPage +
          "&size=3&sort=createdDateTime,desc"
      )
      .then((result) => {
        if (result.data.status == "success" && result.data.data.length > 0) {
          dispatch(
            ierActions.updateAuthorOpenIdeationEdits(
              result.data.data,
              ideationEditRequests.author.open.currPage + 1,
              parseInt(result.data.pagenumber) + 1 >= result.data.totalpages
                ? true
                : false
            )
          );
        }
      });
  };

  // Method to parse the metric value
  const getMetricValue = (type) => {
    // Check if ideation and metrics are existing, else return 0
    if (!ideatorOwnMetrics) return "0".padStart(2, "0");

    // Get the metrics
    let metrics = ideatorOwnMetrics.find((metric) => metric.type === type);

    // If metrics
    if (metrics) return metrics.value.padStart(2, "0");
    else return "0".padStart(2, "0");
  };

  const getFeedbackEmail = () => {
    return "feedback" + "@" + "microideation" + ".com";
  };

  return (
    <React.Fragment>
      <div className="portal_outer_container">
        <Modal
          show={feedbackDlgShow}
          onHide={() => setFeedbackDlgShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Feedback !!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you have a valuable feedback or suggestion for improvement ?
            <br />
            Please send it to <i>{getFeedbackEmail()}</i>.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setFeedbackDlgShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                (window.location.href =
                  "mailto:" +
                  getFeedbackEmail() +
                  "?subject=Microideation: Feedback")
              }
            >
              Open Email Client
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col md={12}>
            {userSession.status !== "ACTIVE" && <ResendActivationLinkSection />}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="section_container top_nav_section">
              <Row>
                <Col md={6} xs={12}>
                  <Container>
                    <Row>
                      <Col
                        xs={3}
                        align="center"
                        lg={{ span: 2 }}
                        md={{ span: 3 }}
                      >
                        <div
                          className={
                            "circle_btn " +
                            (ideationFormActive ? "circle_btn_hover" : "")
                          }
                          data-toggle="tooltip"
                          title="Create Ideation"
                          onClick={() => history.push("/portal/ideation-form")}
                        >
                          <FontAwesomeIcon icon={faLightbulb} />
                        </div>
                      </Col>
                      {/* <Col xs={4} align="center" lg={{ span: 2 }} md={{ span: 3 }}><div className="circle_btn" data-toggle="tooltip" title="Create Template" onClick={() => history.push("/portal/ideation-form/5eba172e2e2138534b0fe01c")}><FontAwesomeIcon icon={faStickyNote} /></div></Col> */}
                      <Col
                        xs={3}
                        align="center"
                        lg={{ span: 2 }}
                        md={{ span: 3 }}
                      >
                        <div
                          className={
                            "circle_btn " +
                            (listOwnIdeationsActive ? "circle_btn_hover" : "")
                          }
                          data-toggle="tooltip"
                          title="My Ideations"
                          onClick={() => history.push("/portal/my-ideations")}
                        >
                          <FontAwesomeIcon icon={faListUl} />
                        </div>
                      </Col>
                      <Col
                        xs={3}
                        align="center"
                        lg={{ span: 2 }}
                        md={{ span: 3 }}
                      >
                        <div
                          className={
                            "circle_btn " +
                            (listOwnIdeationSeriesActive
                              ? "circle_btn_hover"
                              : "")
                          }
                          data-toggle="tooltip"
                          title="Ideation Series"
                          onClick={() =>
                            history.push("/portal/my-ideation-series")
                          }
                        >
                          <FontAwesomeIcon icon={faWindowRestore} />
                        </div>
                      </Col>
                      <Col
                        xs={3}
                        align="center"
                        lg={{ span: 2 }}
                        md={{ span: 3 }}
                      >
                        <div
                          className="circle_btn"
                          data-toggle="tooltip"
                          title="Edit requests"
                          onClick={() =>
                            history.push("/portal/ideation-edit-requests")
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col md={6} xs={12}>
                  <Container>
                    <Row>
                      <Col
                        md={3}
                        xs={3}
                        lg={{ span: 3 }}
                        className="navbar_metric"
                      >
                        <div className="navbar_metric_icon">
                          <FontAwesomeIcon icon={faLightbulb} />
                        </div>
                        <div className="navbar_metric_info">
                          <h3>{getMetricValue("PUBLISH")}</h3>
                        </div>
                        <div className="navbar_metric_label">
                          <p>Posts</p>
                        </div>
                      </Col>
                      <Col md={3} xs={3} lg={3} className="navbar_metric">
                        <div className="navbar_metric_icon">
                          <FontAwesomeIcon icon={faBullhorn} />
                        </div>
                        <div className="navbar_metric_info">
                          <h3>{getMetricValue("VIEW")}</h3>
                        </div>
                        <div className="navbar_metric_label">
                          <p>Views</p>
                        </div>
                      </Col>
                      <Col md={3} xs={3} lg={3} className="navbar_metric">
                        <div className="navbar_metric_icon">
                          <FontAwesomeIcon icon={faHeart} />
                        </div>
                        <div className="navbar_metric_info">
                          <h3>{getMetricValue("FAVORITE")}</h3>
                        </div>
                        <div className="navbar_metric_label">
                          <p>Favorites</p>
                        </div>
                      </Col>
                      <Col md={3} xs={3} lg={3}>
                        <div className="navbar_metric_icon">
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </div>
                        <div className="navbar_metric_info">
                          <h3>{getMetricValue("UPVOTE")}</h3>
                        </div>
                        <div className="navbar_metric_label">
                          <p>Upvote</p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {/* Do not add router here as this will reuse router from App.js */}
            <Switch>
              <Route
                exact
                path="/portal/ideation-form/:id"
                component={IdeationForm}
              />
              <Route
                exact
                path="/portal/ideation-form/unsaved/:id"
                component={IdeationForm}
              />
              <Route
                exact
                path="/portal/ideation-form"
                component={IdeationForm}
              />
              <Route
                exact
                path="/portal/ideation-series-form"
                component={IdeationSeriesForm}
              />
              <Route
                exact
                path="/portal/ideation-series-form/:id"
                component={IdeationSeriesForm}
              />
              <Route
                exact
                path="/portal/my-ideations"
                component={OwnIdeationListForm}
              />
              <Route
                exact
                path="/portal/my-ideation-series"
                component={OwnIdeationSeriesListForm}
              />
              <Route
                exact
                path="/portal/ideation-edit-requests"
                component={IdeationEditRequestListForm}
              />
              <Route
                exact
                path="/portal/ideation-edit-requests/:ideationId"
                component={IdeationEditRequestListForm}
              />
              <Route path="/portal" component={GettingStartedForm} />
            </Switch>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col align="center" style={{ color: "white" }}>
            Queries or suggestions ? &nbsp;
            <br />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setFeedbackDlgShow(true);
              }}
            >
              Send feedback
            </a>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

import {
  faCalendar,
  faChevronCircleRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  refreshAuthorClosedIdeationEdits,
  refreshAuthorOpenIdeationEdits,
  updateAuthorClosedIdeationEdits,
  updateAuthorOpenIdeationEdits,
} from "../../actions/IdeationEditRequestActions";
import {
  IER_STATUS_CLOSED,
  IER_STATUS_OPEN,
  IER_VIEW_TYPE_ALL,
  IER_VIEW_TYPE_IDEATION,
} from "../../support/IdeationEditRequestSpec";
import {
  miAPI,
  URL_IDEATION_EDIT_REQUEST_AUTHOR_FOR_STATUS,
  URL_IDEATION_GET_OWN,
} from "../../support/RestAPISpec";
import { CustomAlert } from "../common/CustomAlert";
import { IdeationEditRequestListForStatus } from "../IdeationEditRequestListForStatus";
import { IdeationeditRequestListItem } from "../IdeationEditRequestListItem";
import "./css/OwnIdeationList.css";
import { IdeationIntro } from "./IdeationIntro";
import { Prompt, useParams } from "react-router-dom";
import * as ivSpec from "../../support/IdeationSpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import * as ierAction from "../../actions/IdeationEditRequestActions";

export const IdeationEditRequestListForm = (props) => {
  // Active status tab
  const [activeStatusTab, setActiveStatusTab] = useState(IER_STATUS_OPEN);

  // Set the ideation
  const [ideation, setIdeation] = useState(ivSpec.Ideation());

  // Reference to the custom alert
  const alertRef = useRef();

  // Reference to the history
  const history = useHistory();

  // The current Ideation
  const ideationData = useSelector((state) => state.ideation);

  // Get the editRequests
  const editRequests = useSelector((state) => state.ideationEditRequests);

  // Get the id from the route params
  const { ideationId } = useParams();

  // Create dispatch
  const dispatch = useDispatch();

  // On load, check the id
  useEffect(() => {
    console.log("Ideation id is : " + ideationId);
    if (ideationId) {
      if (editRequests.author.ideationId !== ideationId) {
        refreshForViewType(IER_VIEW_TYPE_IDEATION, ideationId);
      }
      checkOrLoadIdeation();
    } else if (!ideationId && editRequests.author.ideationId) {
      // This is to clear the filter when the user is coming directly to form
      // while previously the user viewed ideation specific from IdeationForm
      // Without this, the form by default will show the ideation based requests
      refreshForViewType(IER_VIEW_TYPE_ALL, "");
    }
  }, []);

  const refreshForViewType = (viewType, ideationId) => {
    dispatch(ierAction.setEditRequestViewType(viewType, ideationId));
  };

  const checkOrLoadIdeation = () => {
    // Find if the current ideation is matching
    if (ideationData.current.id === ideationId) {
      console.log("checkOrLoadIdeation -> Current is same");
      setIdeation(ideationData.current);
      return;
    }

    // Try to find if the data is in the list
    let ideation = ideationData.list.find((e) => e.id === ideationId);
    // Check if ideation found
    if (ideation) {
      console.log("checkOrLoadIdeation -> Found from list");
      setIdeation(ideation);
      return;
    }

    // Show the progress dialog
    dispatch(blActions.blLoadingInProgress("Loading ideation"));

    // Call the API and fetch
    // Read the details
    miAPI
      .get(URL_IDEATION_GET_OWN + ideationId)
      .then((result) => {
        if (result.data.status == "success") {
          console.log("checkOrLoadIdeation -> Setting from API");
          setIdeation(result.data.data);
          return;
        } else {
          alertRef.current.showAlert({
            heading: "Ideation Fetch",
            message: "Error retrieving ideation : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Ideation Fetch",
          message: "Error fetching ideation",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Render the ideation list for a specific status as tab
  const renderIdeationListForStatusTab = (editRequestStatusList, status) => {
    return (
      <Tab eventKey={status} title={status}>
        <IdeationEditRequestListForStatus
          status={status}
          alertRef={alertRef}
          ideationId={editRequests.author.ideationId}
          editRequestsForStatus={editRequestStatusList}
          viewType={editRequests.author.viewType}
        />
      </Tab>
    );
  };

  return (
    <div className="section_container content_section">
      <div className="content_header">
        <Row>
          <Col md={12}>
            <div className="content_header_heading">
              <h5>
                Ideation edit requests{" "}
                {editRequests.author.viewType === IER_VIEW_TYPE_IDEATION
                  ? " - " + ideation.topic
                  : ""}
              </h5>
            </div>
            <div className="content_header_controls">
              <Row>
                <Col md={{ span: 6, offset: 6 }}>
                  {editRequests.author.viewType === IER_VIEW_TYPE_IDEATION && (
                    <Button
                      onClick={() => {
                        refreshForViewType(IER_VIEW_TYPE_ALL, "");
                        history.push("/portal/ideation-edit-requests");
                      }}
                    >
                      Clear Filter
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content_section_form">
        <Container>
          <CustomAlert ref={alertRef} />
        </Container>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey={IER_STATUS_OPEN}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {renderIdeationListForStatusTab(
                editRequests.author.open,
                IER_STATUS_OPEN
              )}
              {renderIdeationListForStatusTab(
                editRequests.author.closed,
                IER_STATUS_CLOSED
              )}
            </Tabs>
          </Col>
        </Row>
      </div>
    </div>
  );
};

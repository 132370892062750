import React, { useState, useRef, createRef } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  TumblrIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditIcon,
  TumblrShareButton,
} from "react-share";
import { URL_PUBLIC_VIEWER_BASE } from "../../support/RestAPISpec";
import { useEffect } from "react";
import * as masterDataSpec from "../../support/MasterDataSpec";
import { useSelector, useDispatch } from "react-redux";
import * as sdActions from "../../actions/ShareDialogActions";

export const ShareDialog = (props) => {
  // Dispath
  const dispatch = useDispatch();

  // Get the redux store for the shareDialog
  const shareDialog = useSelector((state) => state.shareDialog);

  // Read the masterData
  const masterData = useSelector((state) => state.masterData);

  // item link
  const [itemLink, setItemLink] = useState("");

  // Label for the link
  const [itemLinkLabel, setItemLinkLabel] = useState("Copy the link");

  // Text content
  const [linkContent, setLinkContent] = useState("");

  // Create the reference to the linkText
  const linkRef = createRef();

  // build the link
  const buildItemLink = () => {
    if (props.type === sdActions.SD_TYPE_IDEATION)
      return (
        URL_PUBLIC_VIEWER_BASE + "viewer/slug/ideation/" + props?.item?.slug
      );
    else
      return (
        URL_PUBLIC_VIEWER_BASE + "viewer/ideation-series/" + props?.item?.slug
      );
  };

  const copyToClipboard = () => {
    linkRef.current.select();
    document.execCommand("copy");
    setItemLinkLabel("Link copied !!");
  };

  const getCategoryNameForRef = (categoryRef) => {
    return masterDataSpec.getCategoryForRef(masterData.categories, categoryRef);
  };

  const buildLinkContent = () => {
    if (props.type === sdActions.SD_TYPE_IDEATION)
      setLinkContent(
        "Micro content ( 30 second read) : " +
          props?.item.topic +
          "\n\n" +
          'Visit & learn the quick 30 second microideation on "' +
          props?.item.topic +
          '" under "' +
          props?.item.subject +
          '"' +
          ' of "' +
          getCategoryNameForRef(props?.item.categoryRef) +
          '"' +
          "\n\n"
      );
    else
      setLinkContent(
        "Micro content ( 30 second read) : " +
          props?.item.name +
          "\n\n" +
          props?.item.desc +
          "\n\n" +
          "on category: " +
          getCategoryNameForRef(props?.item.categoryRef) +
          '"' +
          "\n\n"
      );
  };

  const initiateDialog = () => {
    console.log("shareDialog -> item :" + JSON.stringify(props.items));
    // Set the ideationLink
    setItemLink(buildItemLink());
    // Build the link content
    buildLinkContent();
  };

  return (
    <Modal
      show={shareDialog.show}
      onShow={() => {
        initiateDialog();
      }}
      onHide={() => {
        dispatch(sdActions.closeShareDialog());
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share your ideation!!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h6>{itemLinkLabel}</h6>
          </Col>
        </Row>
        <Row style={{ height: "50px" }} className="align-items-center">
          <Col md={10} sm={9} xs={10}>
            <Form.Control
              type="text"
              ref={linkRef}
              value={itemLink}
              onChange={(text) => setItemLink(text)}
            />
          </Col>
          <Col md={2} sm={3} xs={2}>
            <FontAwesomeIcon
              icon={faCopy}
              size="2x"
              color="#7b4397"
              onClick={() => {
                copyToClipboard();
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12}>
            <h6>Share directly to your social media</h6>
          </Col>
        </Row>
        <Row>
          <Col md={12} align="center">
            <FacebookShareButton url={itemLink} quote={linkContent}>
              <FacebookIcon round={true} />
            </FacebookShareButton>
            &nbsp;
            <TwitterShareButton
              url={itemLink}
              title={linkContent}
              hashtags={[getCategoryNameForRef(props.item?.categoryRef)]}
            >
              <TwitterIcon round={true} />
            </TwitterShareButton>
            &nbsp;
            <LinkedinShareButton
              url={itemLink}
              title={
                "Microideation: " + props.type === sdActions.SD_TYPE_IDEATION
                  ? props.item.topic
                  : props.item?.name
              }
              summary={linkContent}
            >
              <LinkedinIcon round={true} />
            </LinkedinShareButton>
            &nbsp;
            <RedditShareButton url={itemLink} title={linkContent}>
              <RedditIcon round={true} />
            </RedditShareButton>
            &nbsp;
            <TumblrShareButton
              url={itemLink}
              title={linkContent}
              hashtags={[getCategoryNameForRef(props.item?.categoryRef)]}
            >
              <TumblrIcon round={true} />
            </TumblrShareButton>
            &nbsp;
            <WhatsappShareButton url={itemLink} title={linkContent}>
              <WhatsappIcon round={true} />
            </WhatsappShareButton>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            dispatch(sdActions.closeShareDialog());
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import React, { createRef, useEffect, useState } from "react";
import "./css/OuterPage.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { miAPI, URL_PASSWORD_RESET_INIT } from "../../support/RestAPISpec";
import * as blActions from "../../actions/BlockingLoaderAction";
import { useDispatch } from "react-redux";
import { CustomAlert } from "../common/CustomAlert";
import { Container } from "react-bootstrap";

export const ResetPasswordRequestForm = (props) => {
  // Define the identifier
  const [identifier, setIdentifier] = useState("");
  const [resetInitiated, setResetInitiated] = useState(false);

  // Create the dispatch
  const dispatch = useDispatch();

  // The alert ref
  const alertRef = React.useRef(null);

  // Disable the loading if already in progress
  useEffect(() => {
    dispatch(blActions.blLoadingDone());
  }, []);

  // Initiate the password call
  const initiatePasswordReset = (event) => {
    // Prevent the default action
    event.preventDefault();

    // Clear the alertRef
    alertRef.current.clear();

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Requesting Link..."));

    // Create the form data
    var formData = new FormData();

    // Append the identifier
    formData.append("identifier", identifier);

    // Make the API call
    miAPI
      .post(URL_PASSWORD_RESET_INIT, formData)
      .then((result) => {
        if (result.data.status === "success") {
          alertRef.current.showAlert({
            heading: "Reset",
            message: "Link sent succesfully!",
            variant: "success",
          });
          setResetInitiated(true);
        } else {
          alertRef.current.showAlert({
            heading: "Reset",
            message: result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Reset",
          message: "Unable to initate password reset",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <React.Fragment>
      <div id="logreg-forms">
        {!resetInitiated && (
          <React.Fragment>
            <div className="header">
              <h3 className="h3 font-weight-normal center-align">
                <strong>Reset Password</strong>
              </h3>
            </div>
            <form
              className="form-reset"
              onSubmit={(e) => initiatePasswordReset(e)}
            >
              <CustomAlert ref={alertRef} />
              <h6 className="font-weight-normal font-weight-normal h6">
                Enter details..
              </h6>
              <input
                type="identifier"
                id="identifier"
                className="form-control"
                placeholder="Email / Username"
                required=""
                autoFocus
                onChange={(e) => setIdentifier(e.target.value)}
              />
              <button className="btn btn-primary btn-block" type="submit">
                Reset Password
              </button>
              <Link to="/login">
                <FontAwesomeIcon icon={faAngleLeft} /> Back
              </Link>
            </form>
          </React.Fragment>
        )}

        {resetInitiated && (
          <Container align="center">
            <br />
            <h4>Reset Link Sent !!</h4>
            <p>
              We have sent a reset password link to your registered email
              address. Follow the link to update.
            </p>
            <Link to="/login">
              <FontAwesomeIcon icon={faAngleLeft} /> Back
            </Link>
            <br />
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export const IDEATOR_METRIC_DATA_FETCH = "IDEATOR_METRIC_DATA_FETCH";
export const IDEATOR_METRIC_DATA_UPDATE = "IDEATOR_METRIC_DATA_UPDATE";

export const ideatorMetricDataFetch = () => {
  return {
    type: IDEATOR_METRIC_DATA_FETCH,
    payload: {},
  };
};

export const ideatorMetricDataUpdate = (ideatorMetrics) => {
  return {
    type: IDEATOR_METRIC_DATA_UPDATE,
    payload: ideatorMetrics,
  };
};

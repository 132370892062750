import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Button, Container, Form, Alert } from "react-bootstrap";
import { CustomAlert } from "../common/CustomAlert";
import SelectSearch from "react-select-search";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import "./css/ideation-series-styles.css";
import { IdeationSeriesDraggableItem } from "../series/IdeationSeriesDraggableItem";
import * as masterDataSpec from "../../support/MasterDataSpec";
import * as idSeriesSpec from "../../support/IdeationSeriesSpec";
import * as idmActions from "../../actions/IdeationMasterDataActions";
import {
  miAPI,
  URL_IDEATION_SERIES_GET_OWN,
  URL_IDEATION_SERIES_PUBLISH,
  URL_IDEATION_SERIES_SAVE,
  URL_IDEATION_SERIES_SUSPEND,
  URL_MASTER_DATA_READ,
} from "../../support/RestAPISpec";
import { useParams } from "react-router";
import * as idSeriesAction from "../../actions/IdeationSeriesAction";
import * as blActions from "../../actions/BlockingLoaderAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const IdeationSeriesForm = (props) => {
  // Category data
  const [saveBtnState, setSaveBtnState] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [categoryRef, setCategoryRef] = useState("");
  const [descCharCount, setDescCharCount] = useState(0);
  const [sourceSeriesFilter, setSourceSeriesFilter] = useState("");

  const [categories, setCategories] = useState([]);
  const [catChangeDlgShow, setCatChangeDlgShow] = useState(false);

  const [items, setItems] = useState([]);
  const [seriesIdeations, setSeriesIdeations] = useState([]);
  const [ideationSeriesData, setIdeationSeriesData] = useState(
    idSeriesSpec.IdeationSeries()
  );

  const [masterCategories, setMasterCategories] = useState(
    masterDataSpec.StandardIdeationMasterData().categories
  );
  // Reference to the redux dispatcher
  const dispatch = useDispatch();

  // The current Ideation
  const currIdSeries = useSelector((state) => state.ideationSeries.current);

  // Get the ideationSeriesList
  const ideationSeriesList = useSelector((state) => state.ideationSeries.list);

  // Get the id from the route params
  const { id } = useParams();

  // Get the path
  const { path } = props.match;

  // Reference to the custom alert
  const alertRef = useRef();

  // Get the pubIdeations
  const pubIdeations = useSelector(
    (state) => state.ideatorProfile.pubIdeations
  );

  // Read the masterData
  const masterData = useSelector((state) => state.masterData);

  // On load, set the userSession.username as author
  useEffect(() => {
    dispatch(idmActions.ideationMasterDataFetch());
  }, []);

  // Watch the masterData
  useEffect(() => {
    // Check if masterDAta is defined
    if (
      !masterData ||
      masterData.expired === undefined ||
      masterData.expired === true
    )
      fetchMasterData();
    else setMasterCategories(masterData.categories);
  }, [masterData]);

  useEffect(() => {
    setIdeationSeriesData({
      ...ideationSeriesData,
      ideations: getIdeationSeriesItems(),
    });
  }, [seriesIdeations]);

  useEffect(() => {
    refreshAvailableIdeations();
  }, [categoryRef]);

  // Watch the ideationSeriesData["desc"]
  useEffect(() => {
    if (ideationSeriesData.desc) {
      setDescCharCount(ideationSeriesData.desc.length + " / 200");
    } else {
      setDescCharCount("0 / 200");
    }
  }, [ideationSeriesData.desc]);

  // Watch the path changes and trigger the view based on the id
  // retrieved from the params.
  // IMPORTANT NOTE: This can be also done by watching the id directly.
  // But path provides more details on the path changes when there are
  // more actions that we need to handle
  useEffect(() => {
    // Check if the id is present
    if (id) {
      fetchIdeationForEdit(id);
    } else {
      alertRef.current.clear();
      dispatch(idSeriesAction.ideationSeriesCreateNew());
    }
  }, [path]);

  // We can use useEffect with the value referenced as the useSelector
  // reference to identifying the effect of change to the values
  useEffect(() => {
    const parentCat = masterDataSpec.getParentCategoryRefForCategory(
      masterData.categories,
      currIdSeries.categoryRef
    );
    setParentCategory(parentCat);
    setCategories(
      masterDataSpec.getCategoryOptions(masterData.categories, parentCat)
    );
    setIdeationSeriesData(currIdSeries);
    buildSeriesIdeationsForEdit();
    setCategoryRef(currIdSeries.categoryRef);

    // its a new currIdSeries , then clear the items
    if (currIdSeries.categoryRef === "") {
      setItems([]);
    }
  }, [currIdSeries]);

  useEffect(() => {
    if (sourceSeriesFilter !== "") {
      setItems(items.filter((e) => e.topic.includes(sourceSeriesFilter)));
    } else {
      refreshAvailableIdeations();
    }
  }, [sourceSeriesFilter]);

  // Read the categories
  const fetchMasterData = () => {
    console.log("fetching master data");
    // If not found, then  need to read the data from server and read
    miAPI.get(URL_MASTER_DATA_READ).then((result) => {
      if (result.data.status == "success") {
        dispatch(idmActions.ideationMasterDataUpdate(result.data.data));
      }
    });
  };

  const onDragEnd = (result) => {
    console.log("onDragEndDrag result: " + JSON.stringify(result));

    // return if item was dropped outside
    if (!result.destination) return;
    // return if the item was dropped in the same place
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    // get the items array
    const cpySeriesIdeation = [...seriesIdeations];
    const cpyItems = [...items];

    if (
      result.destination.droppableId === "drpSeriesIdeations" &&
      result.source.droppableId === "ideationSource"
    ) {
      // get the draggedItems
      const draggedItem = items[result.source.index];
      console.log("new item size: " + cpySeriesIdeation.length);
      // delete the item from source position and insert it to the destination positon
      if (cpySeriesIdeation.length !== 0) {
        cpySeriesIdeation.splice(result.destination.index, 0, draggedItem);
        cpyItems.splice(result.source.index, 1);
      } else {
        cpySeriesIdeation.push(draggedItem);
        cpyItems.splice(result.source.index, 1);
      }
      console.log("New items: " + JSON.stringify(cpySeriesIdeation));
      // update state
      setItems([...cpyItems]);
      setSeriesIdeations([...cpySeriesIdeation]);
    } else if (
      result.destination.droppableId === "ideationSource" &&
      result.source.droppableId === "drpSeriesIdeations"
    ) {
      // get the draggedItems
      const draggedItem = seriesIdeations[result.source.index];
      // delete the item from source position and insert it to the destination positon
      if (cpyItems.length !== 0) {
        cpyItems.splice(result.destination.index, 0, draggedItem);
        cpySeriesIdeation.splice(result.source.index, 1);
      } else {
        cpyItems.push(draggedItem);
        cpySeriesIdeation.splice(result.source.index, 1);
      }

      // update state
      setItems([...cpyItems]);
      setSeriesIdeations([...cpySeriesIdeation]);
    } else if (
      result.destination.droppableId === "drpSeriesIdeations" &&
      result.source.droppableId === "drpSeriesIdeations"
    ) {
      // get the draggedItems
      const draggedItem = seriesIdeations[result.source.index];
      // delete the item from source position and insert it to the destination positon
      cpySeriesIdeation.splice(result.source.index, 1);
      cpySeriesIdeation.splice(result.destination.index, 0, draggedItem);

      // update state
      setSeriesIdeations([...cpySeriesIdeation]);
    } else {
      // get the items array
      const newItems = [...items];
      // get the draggedItems
      const draggedItem = newItems[result.source.index];
      // delete the item from source position and insert it to the destination positon
      newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, draggedItem);
      console.log("New items: " + JSON.stringify(newItems));
      // update state
      setItems([...newItems]);
    }
  };

  useEffect(() => {
    handleParentCategoryChange(parentCategory);
  }, [parentCategory]);

  // Handle the category change
  const handleParentCategoryChange = (parentCategoryRef) => {
    setCategories(
      masterDataSpec.getCategoryOptions(masterCategories, parentCategoryRef)
    );
  };

  const confirmCategoryChange = () => {
    setCategoryRef(ideationSeriesData.categoryRef);
    setCatChangeDlgShow(false);
    setSeriesIdeations([]);
  };

  const cancelCategoryChange = () => {
    setCatChangeDlgShow(false);
    setIdeationSeriesData({
      ...ideationSeriesData,
      ["categoryRef"]: categoryRef,
    });
  };

  // Handle the category change
  const handleCategoryChange = (selCatRef) => {
    // Set the category ref
    setIdeationSeriesData({ ...ideationSeriesData, categoryRef: selCatRef });

    // If the categoryRef is not empty and new one is also same, no action
    if (categoryRef !== "" && categoryRef === selCatRef) {
      return;
    }

    // If the ideationSeries is new and there are no ideations
    if (
      categoryRef !== undefined &&
      categoryRef !== "" &&
      categoryRef !== selCatRef &&
      seriesIdeations.length > 0
    ) {
      setCatChangeDlgShow(true);
    } else {
      setCategoryRef(selCatRef);
    }
  };

  const buildSeriesIdeationsForEdit = () => {
    let seriesData = currIdSeries.ideations.map((e) => {
      let item = pubIdeations.find((x) => x.id === e.id);
      return {
        index: e.index,
        id: e.id,
        topic: item.topic,
        subject: item.subject,
        categoryRef: item.categoryRef,
      };
    });

    setSeriesIdeations(seriesData);
  };

  const refreshAvailableIdeations = () => {
    // If category ref is not specified, then return
    if (categoryRef === undefined || categoryRef === "") return;

    // Set the index
    let index = 1;

    setItems(
      pubIdeations
        .filter((e) => e.categoryRef === categoryRef)
        .filter((e) => {
          let item = ideationSeriesData.ideations.find((x) => x.id === e.id);
          console.log("item found: " + item);
          if (item === undefined) return true;
          else return false;
        })
        .map((e) => ({
          index: index++,
          id: e.id,
          topic: e.topic,
          subject: e.subject,
          categoryRef: e.categoryRef,
        }))
    );
  };

  // Method to set the ideation field value and update the
  // ideationSeriesData state
  const setIdeationFieldValue = (field, value) => {
    const idData = { ...ideationSeriesData, [field]: value };
    setIdeationSeriesData(idData);
  };

  // Suspend the ideation
  const suspendSeries = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress());

    miAPI
      .post(URL_IDEATION_SERIES_SUSPEND, "seriesId=" + ideationSeriesData.id)
      .then((result) => {
        // Check the result
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Series Suspend",
            message: "Series is suspended now.",
            variant: "success",
          });
          ideationSeriesData.status = "SUSPENDED";
          console.log(
            "Series on suspend: " + JSON.stringify(ideationSeriesData)
          );
          dispatch(idSeriesAction.ideationSeriesSuspended(ideationSeriesData));
        } else {
          alertRef.current.showAlert({
            heading: "Series Suspend",
            message: "Error suspending series : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Series Suspend",
          message: "Error suspending series",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Publish the ideation
  const publishSeries = () => {
    // Show the progress bar
    dispatch(blActions.blLoadingInProgress());

    miAPI
      .post(URL_IDEATION_SERIES_PUBLISH, "seriesId=" + ideationSeriesData.id)
      .then((result) => {
        console.log("Result " + JSON.stringify(result));
        // Check the result
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Series Publish",
            message: "Congrats!!, You have published the series",
            variant: "success",
          });
          // ideationSeriesData.id = result.data.data;
          ideationSeriesData.status = "PUBLISHED";
          console.log(
            "Ideation on publish: " + JSON.stringify(ideationSeriesData)
          );
          dispatch(idSeriesAction.ideationSeriesPublished(ideationSeriesData));
        } else {
          alertRef.current.showAlert({
            heading: "Series Publish",
            message: "Error publishing series : \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Series Publish",
          message: "Error publishing series",
          variant: "warning",
        });
        console.log("error: " + JSON.stringify(error));
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  // Read the user's own ideation series for editing
  // On success, dispatch the edit option
  const fetchIdeationForEdit = (idSeriesRef) => {
    // Check if the list contains the id
    if (ideationSeriesList && ideationSeriesList.length > 0) {
      let ideationSeries = ideationSeriesList.find(
        (idtn) => idtn.id === idSeriesRef
      );
      if (ideationSeries) {
        dispatch(idSeriesAction.ideationSeriesEdit(ideationSeries));
        return;
      }
    }

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Loading series"));

    // Read the details
    miAPI
      .get(URL_IDEATION_SERIES_GET_OWN + idSeriesRef)
      .then((result) => {
        if (result.data.status == "success") {
          const idSeriesObj = result.data.data.series;
          // ideationObj.editorState = htmlToEditorState(result.data.data.content);
          console.log("Edit ideation : " + JSON.stringify(idSeriesObj));
          dispatch(idSeriesAction.ideationSeriesEdit(idSeriesObj));
        } else {
          alertRef.current.showAlert({
            heading: "Series Fetch",
            message:
              "Error retrieving ideation series: \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        console.log("error : " + JSON.stringify(error));
        alertRef.current.showAlert({
          heading: "Ideation Series Fetch",
          message: "Error fetching ideation series",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  const getIdeationSeriesItems = () => {
    let index = 1;
    return seriesIdeations.map((e) => ({ index: index++, id: e.id }));
  };

  // Method to handle the saving of the ideation
  const saveSeries = () => {
    if (catChangeDlgShow === true) {
      alertRef.current.showAlert({
        heading: "Category isssue",
        message: "Please accept the category change",
        variant: "warning",
      });
      return;
    }

    console.log("Ideation series data: " + JSON.stringify(ideationSeriesData));

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress());

    miAPI
      .post(URL_IDEATION_SERIES_SAVE, ideationSeriesData)
      .then((result) => {
        console.log("Result " + JSON.stringify(result));
        // Check the result
        if (result.data.status == "success") {
          alertRef.current.showAlert({
            heading: "Series Save",
            message: "Series saved successfully!!",
            variant: "success",
          });
          ideationSeriesData.id = result.data.data.id;
          ideationSeriesData.slug = result.data.data.slug;
          dispatch(idSeriesAction.ideationSeriesSaved(ideationSeriesData));
        } else {
          alertRef.current.showAlert({
            heading: "Series Save",
            message: "Error saving ideation series: \n" + result.data.errordesc,
            variant: "warning",
          });
        }
      })
      .catch((error) => {
        alertRef.current.showAlert({
          heading: "Series Save",
          message: "Error saving series",
          variant: "warning",
        });
      })
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <div className="section_container content_section">
      <div className="content_header">
        <Row>
          <Col md={12}>
            <div className="content_header_heading">
              <Row>
                <Col md={12}>
                  {!ideationSeriesData.id && <h5>New Series</h5>}
                  {ideationSeriesData.id && (
                    <h5>Update Series ({ideationSeriesData.status})</h5>
                  )}
                </Col>
              </Row>
            </div>
            <div className="content_header_controls">
              <Row>
                <Col md={{ span: 4 }}></Col>
                <Col md={{ span: 4 }}>
                  {(ideationSeriesData.status === "NEW" ||
                    ideationSeriesData.status === "SUSPENDED") && (
                    <Button
                      variant="primary"
                      disabled={
                        !(
                          ideationSeriesData.status === "NEW" ||
                          ideationSeriesData.status === "SUSPENDED"
                        ) || !ideationSeriesData.id
                      }
                      onClick={() => publishSeries()}
                      block
                    >
                      Publish
                    </Button>
                  )}
                  {ideationSeriesData.status === "PUBLISHED" && (
                    <Button
                      variant="warning"
                      disabled={!(ideationSeriesData.status === "PUBLISHED")}
                      onClick={() => suspendSeries()}
                      block
                    >
                      Suspend
                    </Button>
                  )}
                </Col>
                <Col md={4}>
                  <Button
                    disabled={saveBtnState}
                    variant="success"
                    onClick={() => saveSeries()}
                    block
                  >
                    Save Series
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content_section_form">
        <Container>
          <CustomAlert ref={alertRef} />
        </Container>
        <Form>
          <Row>
            <Col lg={{ span: 6 }} md={{ span: 6 }} xs={12}>
              <Container>
                <Row>
                  <Col
                    md={{ span: 12 }}
                    lg={{ span: 10, offset: 1 }}
                    sm={{ span: 12 }}
                    xl={{ span: 8, offset: 2 }}
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      tabIndex="1"
                      value={ideationSeriesData.name}
                      maxLength={50}
                      onChange={(e) =>
                        setIdeationFieldValue(e.target.name, e.target.value)
                      }
                      placeholder="Name"
                      required
                    />
                    <p>
                      <br />
                      Choose the <b>"Top category"</b> and <b>"Category"</b> to
                      see the ideations under them.
                    </p>
                    <SelectSearch
                      options={masterDataSpec.getParentCategoryOptions(
                        masterCategories
                      )}
                      value={parentCategory}
                      name="parentCategory"
                      placeholder="Top Category"
                      search={true}
                      tabIndex="3"
                      onChange={(e) => handleParentCategoryChange(e)}
                    />
                    <SelectSearch
                      options={categories}
                      value={ideationSeriesData.categoryRef}
                      tabIndex="4"
                      name="categoryRef"
                      emptyMessage="Please choose top category"
                      placeholder="Category"
                      search={true}
                      onChange={(e) => handleCategoryChange(e)}
                    />
                    <Alert show={catChangeDlgShow} variant="warning">
                      <Alert.Heading>Confirm change category</Alert.Heading>
                      <p>
                        Changing the category will reset the available list as
                        well as the series ideations. Please confirm
                      </p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={() => confirmCategoryChange()}
                          variant="outline-warning"
                        >
                          Change
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => cancelCategoryChange()}
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Alert>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg={{ span: 6 }} md={{ span: 6 }} xs={12}>
              <Container>
                <Row>
                  <Col
                    md={{ span: 12 }}
                    lg={{ span: 10, offset: 1 }}
                    sm={{ span: 12 }}
                    xl={{ span: 8, offset: 2 }}
                  >
                    <Form.Control
                      as="textarea"
                      tabIndex="2"
                      value={ideationSeriesData.desc}
                      name="desc"
                      rows={3}
                      placeholder="A proper description elaborating the series"
                      maxLength={200}
                      minLength={50}
                      onChange={(e) =>
                        setIdeationFieldValue(e.target.name, e.target.value)
                      }
                      required
                    />
                    <Form.Label>
                      <b>Characters :</b> {descCharCount}
                    </Form.Label>
                    <Alert variant="info">
                      <FontAwesomeIcon icon={faInfoCircle} />
                      &nbsp; Drag ideation from <b>
                        "Available ideations"
                      </b> to <b>"Series ideation"</b>. You can re-order the
                      ideations and also remove by dragging back to{" "}
                      <b>"Available ideations"</b>
                    </Alert>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Form>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col
              lg={{ span: 6 }}
              md={{ span: 6 }}
              xs={12}
              className="content_form_container"
            >
              <Container>
                <Row>
                  <Col
                    md={{ span: 12 }}
                    lg={{ span: 10, offset: 1 }}
                    sm={{ span: 12 }}
                    xl={{ span: 8, offset: 2 }}
                  >
                    <h5>Available Ideations</h5>
                    <Form.Control
                      name="source-series-filter"
                      placeholder="Filter ideations"
                      value={sourceSeriesFilter}
                      onChange={(e) => setSourceSeriesFilter(e.target.value)}
                    />
                    <Droppable droppableId="ideationSource">
                      {(provided) => (
                        <div
                          className="ideation-series-ideation-list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {items.map((item, index) => (
                            <Draggable
                              key={item.id.toString()}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(drgProvided) => (
                                <IdeationSeriesDraggableItem
                                  index={index}
                                  drgProvided={drgProvided}
                                  item={item}
                                  masterCategories={masterCategories}
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg={{ span: 6 }} md={{ span: 6 }} xs={12}>
              <Container>
                <Row>
                  <Col
                    md={{ span: 12 }}
                    lg={{ span: 10, offset: 1 }}
                    sm={{ span: 12 }}
                    xl={{ span: 8, offset: 2 }}
                  >
                    <h5>Series Ideations</h5>
                    <Form.Control
                      name="source-series-filter"
                      placeholder="Filter ideations"
                      disabled
                    />
                    <Droppable droppableId="drpSeriesIdeations">
                      {(provided) => (
                        <div
                          className="ideation-series-ideation-list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {seriesIdeations.map((item, index) => (
                            <Draggable
                              key={item.id.toString()}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(drgProvided) => (
                                <IdeationSeriesDraggableItem
                                  index={index}
                                  drgProvided={drgProvided}
                                  item={item}
                                  masterCategories={masterCategories}
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </DragDropContext>
      </div>
    </div>
  );
};

import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as blActions from "../../actions/BlockingLoaderAction";
import {
  URL_PUBLIC_VIEWER_IDEATION,
  URL_PUBLIC_VIEWER_IDEATION_SLUG
} from "../../support/RestAPISpec";
import { IdeationView } from "../common/IdeationView";
import gstoreImg from "../outer/img/google-play-badge.svg";
import astoreImg from "./img/app-store-badge.svg";
import "./css/viewer-styles.css";



export const IdeationViewer = (props) => {
  // Refer to the history
  const history = useHistory();

  // Reference to the redux dispatcher
  const dispatch = useDispatch();

  // Get the id from the route params
  const { id, slug } = useParams();

  // Ideation
  const [ideation, setIdeation] = useState({});

  // Error state
  const [ideationError, setIdeationError] = useState(false);

  useEffect(() => {
    fetchPublicIdeation();
  }, []);

  const fetchPublicIdeation = () => {
    console.log("Slug is : " + slug);
    console.log("Id is : " + id);

    // By default set to find by slug
    let url = URL_PUBLIC_VIEWER_IDEATION_SLUG + "/" + slug;

    // If the slug is not present and is there,then find by id
    if (slug === undefined && id) {
      url = URL_PUBLIC_VIEWER_IDEATION + "/" + id;
    }

    // Show the progress bar
    dispatch(blActions.blLoadingInProgress("Loading Ideation..."));

    // Use Axios directly to avoid using the interceptors when
    // session is not there.
    Axios.get(url, {
      withCredentials: false,
    })
      .then((result) => {
        if (result.data.status === "success") {
          var item = result.data.data.ideation;
          document.title =
            "Microideation: " +
            item.topic +
            " ( Subject:  " +
            item.subject +
            " Category: " +
            result.data.data.categoryName +
            " ) ";
          // ideationRef.current.setSubject(item.subject);
          // ideationRef.current.setContent(item.content);
          // ideationRef.current.setAuthor(item.author);
          // ideationRef.current.setTopic(item.topic);
          // ideationRef.current.setCategory(result.data.data.categoryName);

          // Set the ideation
          setIdeation({
            subject: item.subject,
            topic: item.topic,
            author: item.author,
            category: result.data.data.categoryName,
            content: item.content,
            favorites: item.favorites,
            upvotes: item.votes,
            views: item.views,
          });
        } else {
          setIdeationError(true);
        }
      })
      .catch(() => setIdeationError(true))
      .then(() => dispatch(blActions.blLoadingDone()));
  };

  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }} sm={{ span: 12 }} className="mt-3">
        <div className="viewer-style-container">
          {!ideationError && <IdeationView ideation={ideation} source="viewer" />}
          {ideationError && (
            <Alert variant="danger" align="center">
              <Alert.Heading>
                Ideation Error
                <hr />{" "}
              </Alert.Heading>
              <h5 align="center" style={{ marginTop: "0px" }}>
                <strong>
                  <i>
                    Sorry, unable to retrieve the ideation. Ensure that its a
                    valid link!!
                  </i>
                </strong>
              </h5>
              <hr />
              <Button
                variant="primary"
                onClick={() => {
                  history.push("/");
                }}
              >
                Home
              </Button>
            </Alert>
          )}
        </div>
        <div className="viewer-style-container">
          <Row>
            <Col md={12} align="center">
              <h5 className="mt-3 fg-color-white">
                Download the app free for more content with recommendations and
                preferences.
              </h5>
              <a
                className="badge-link"
                href="https://play.google.com/store/apps/details?id=com.microideation.app"
                target="_blank"
              >
                <img src={gstoreImg} width="240" alt="" />
              </a>
              <p></p>
              <a
                className="badge-link"
                href="https://apps.apple.com/us/app/microideation/id1582752866"
                target="_blank"
              >
                <img src={astoreImg} width="240" alt="" />
              </a>
            </Col>
          </Row>
        </div>
        <div className="viewer-style-container mt-3">
          <Row>
            <Col md={12} align="center">
              <h3 className="fg-color-white">
                Are you a subject matter expert ?
              </h3>
              <p className="fg-color-white">
                Craft beautiful single page ideations on topics under your area
                of expertise and get recognized.
              </p>
              <p className="center">
                <Button
                  className="mb-2 mr-2"
                  variant="primary outline"
                  onClick={() => history.push("/register")}
                >
                  Sign Up Now
                </Button>
                <Button
                  className="mb-2"
                  variant="outline-light"
                  onClick={() => history.push("/about")}
                >
                  Learn more..
                </Button>
              </p>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

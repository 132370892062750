import React, { useRef, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { URL_PUBLIC_RANDOM_IDEATIONS } from "../../support/RestAPISpec";
import Axios from "axios";
import { IdeationPreview } from "./IdeationPreview";
import "./css/random-ideations.css";
import { IdeationView } from "./IdeationView";

export const RandomIdeationView = (props) => {
  const [ideations, setIdeations] = useState([]);

  const [randIdeationSize, setRandIdeationSize] = useState(3);

  useEffect(() => {
    fetchRandomIdeations();
  }, []);

  const fetchRandomIdeations = () => {
    // Use Axios directly to avoid using the interceptors when
    // session is not there.
    Axios.get(URL_PUBLIC_RANDOM_IDEATIONS, { withCredentials: false }).then(
      (result) => {
        console.log("REsult is : " + JSON.stringify(result));
        setRandIdeationSize(
          result.data.data.length > 6 ? 6 : result.data.data.length
        );
        console.log("response:" + JSON.stringify(result.data.data));
        if (result.data.status == "success") {
          setIdeations(
            result.data.data.map((e) => ({
              subject: e.ideation.subject,
              topic: e.ideation.topic,
              author: e.ideation.author,
              category: e.ideation.categoryName,
              content: e.ideation.content,
              favorites: e.ideation.favorites,
              votes: e.ideation.votes,
              views: e.ideation.views,
            }))
          );
        }
      }
    );
  };

  return (
    <Carousel
      className={
        "ideation_carousel " +
        (props?.indicatorTheme === "dark"
          ? "carousel_dark_indicators"
          : "carousel_light_indicators")
      }
    >
      {ideations.slice(0, randIdeationSize).map((ideation) => (
        <Carousel.Item>
          <IdeationView ideation={ideation} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

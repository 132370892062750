import React from "react";
import "./css/Ideation.css";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faHeart,
  faThumbsUp,
  faThumbsDown,
  faBullhorn,
  faBullseye,
  faUser,
  faSuperscript,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import { Ideation } from "../../support/IdeationSpec";

export const IdeationView = (props) => {
  console.log("props: " + JSON.stringify(props.ideation));

  const limitedString = (str, len) => {
    if (new String(str).length > len)
      return new String(str).substring(0, len) + "..";
    else return str;
  };

  return (
    <div className="ideation_viewport">
      <Container>
        <Row className="align-items-center">
          <Col
            md={7}
            sm={7}
            xs={7}
            style={{ paddingRight: 0 }}
            className="ideation_topic"
          >
            <h5
              style={{ marginBottom: 0 }}
              data-toggle="tooltip"
              title={props.ideation.topic}
            >
              {limitedString(props.ideation.topic, 14)}
            </h5>
          </Col>
          <Col md={5} sm={5} xs={5} align="right">
            <div className="ideation-field_text">
              <Badge
                pill
                variant="warning"
                data-toggle="tooltip"
                title={props.ideation.category}
              >
                {limitedString(props.ideation.category, 10)}
              </Badge>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ideation-field_text">
              <b>on</b> <i>{limitedString(props.ideation.subject, 16)}</i> ,{" "}
              <b>by</b> <i>{limitedString(props.ideation.author, 12)}</i>
            </div>
          </Col>
        </Row>
        <Row className="ideation-margin-top-10px">
          <Col>
            <div className="ideation_content">
              {props.ideation.content && parse(props.ideation.content)}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="ideation_bottom_controls align-items-center">
        {props.ideation.source === "viewer" ? (
          <Row>
            <Col md={12} sm={12} align="center" className="mt-1">
              &nbsp;
              <a
                className="badge-link ideation-a-href"
                href="https://play.google.com/store/apps/details?id=com.microideation.app"
                target="_blank"
              >
                DOWNLOAD APP
              </a>
              &nbsp; | &nbsp;
              <a
                className="badge-link ideation-a-href"
                href="https://portal.microideation.com/about"
                target="_blank"
              >
                ABOUT
              </a>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              align="center"
              md={4}
              sm={4}
              xs={4}
              className="align-self-center"
            >
              <FontAwesomeIcon icon={faHeart} color="#fff" size="1x" />
              &nbsp;
              {props.ideation.favorites}
            </Col>
            <Col align="center" md={4} sm={4} xs={4}>
              <FontAwesomeIcon icon={faThumbsUp} color="#fff" />
              &nbsp;
              {props.ideation.votes}
            </Col>
            <Col align="center" md={4} sm={4} xs={4}>
              <FontAwesomeIcon icon={faEye} color="#fff" />
              &nbsp;
              {props.ideation.views}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
